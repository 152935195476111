import React, { Fragment, useEffect, useState } from 'react'
import { Row, Card, Typography, Layout, message, Button, Skeleton, Col, Space } from 'antd'
import {Link,Redirect} from 'react-router-dom'
import Axios from 'axios'
import moment from 'moment';
import { url } from '../../config/global'
import { Bar } from 'react-chartjs-2'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LeftCircleOutlined } from '@ant-design/icons';
import 'chartjs-plugin-labels';
import './Dashboard.less'

const { Text } = Typography
const { Content } = Layout


function Dashboard() {

    const [chart, setChart] = useState({})
    const [groups, setGroups] = useState([])
    const [loading, setLoading] = useState(false)
    const [slider, setSlider] = useState(1000)
    const [valueColor, setColor] = useState ('green')

    useEffect(() => {
      getData()
      getGroup()
      getSlider()
    }, [])

    const getData = () => {
      setLoading(true)
      Axios.get(`${url}/dashboard-chart`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
      }).then(res => {
        setChart(res.data)
      }).catch(err => {
        if (err.response) {
          message.error(err.response.data.message)
        }
      })
      .finally(() => {
        setLoading(false)
      })
    }

    const getGroup = () => {
      Axios.get(`${url}/group/get`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
      }).then(res => {
        setGroups(res.data.data)
      }).catch(err => {
        if (err.response) {
          message.error(err.response.data.message)
        }
      })
    }

    const getSlider = () => {
      Axios.get(`${url}/settings/get`, {
        params: {
          variable: 'slider'
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
      }).then(res => {
        setSlider(res.data.data.value[0].name)
        console.log(res.data.data.value[0].name)
      }).catch(err => {
        if (err.response) {
          message.error(err.response.data.message)
        }
      })
    }
    
    const heightBar = 100;
    
    if (!sessionStorage.getItem('token')) {
      return <Redirect to="/login" />
    }

    const settings = {
      // dots: false,
      infinite: true,
      arrows: true,
      autoplay: true,
      autoplaySpeed: parseInt(slider),

      customPaging: function(i) {
        return (
          <span className="pagination">
            {groups[i].name}
          </span>
        );
      },
      pauseOnHover: true,
      dots: true,
      dotsClass: "slick-dots slick-thumb slick-custom",
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true
    }

    return (
        <Fragment>
            <Content className="content">
                <Row gutter={[10, 20]}>
                  <Col xs={12} md={11}>
                    <Link to={sessionStorage.getItem('redirect')}>
                        <Button className="mr-10" type="primary"><LeftCircleOutlined />Return to Menu</Button>
                    </Link>
                  </Col>
                  <Col xs={12} md={4}>
                    <img src={ require('../../assets/img/logo.png') } alt="Sanoh Logo" width="100px" height="50px" />
                  </Col>
                  <Col xs={12} md={2}>
                    <div className="label-min-stock">
                      Min Stock
                    </div>
                  </Col>
                  <Col xs={12} md={2}>
                    <div className="label-max-stock">
                      Max Stock
                    </div>
                  </Col>
                  <Col xs={12} md={2}>
                    <div className="label-current-stock">
                      Current Stock
                    </div>
                  </Col>
                </Row>
                
                <div style={{
                  overflowX: 'hidden',
                  overflowY: 'hidden'
                }}
                >
                <Slider {...settings}>
                  {
                    loading ? (
                        <Col sm={24}>
                            <Skeleton loading={loading} />
                        </Col>
                    ) :
                    groups && groups.map(group => {
                      const label=chart[group.name].label
                      const data=chart[group.name].data
                      const min_stock=chart[group.name].min_stock
                      const max_stock=chart[group.name].max_stock

                      console.log(data)
                      console.log(min_stock)
                      console.log(max_stock)
                      
                      //const fontColor = valueColor === currentStock ? 'blue' : valueColor === minimalStock ? 'red' : 'green'

                      return(
                        <div key={(data, index) => index} >
                          <Card 
                          //title={group.name} 
                          extra={ <Text strong></Text>}
                          >
                          <Bar
                          data={{
                            labels: label,
                            datasets: [
                              {
                                label: 'Min Stock',
                                data: min_stock,
                                type: 'line',
                                backgroundColor: 'transparent',
                                borderColor: '#ff3300',
                                borderWidth: 2
                              },
                              {
                                label: 'Max Stock',
                                color: 'green',
                                data: max_stock,
                                type: 'line',
                                backgroundColor: 'transparent',
                                borderColor: '#1aff1a',
                                borderWidth: 2
                              },
                              {
                                label: 'Stock',
                                data: data,
                                backgroundColor: '#1a8cff'
                              }
                            ]
                          }}
                          height={heightBar}
                          options={{
                            legend: {
                              display: false
                            },
                            plugins: {
                              labels:{
                                render: 'value',
                                fontColor: ['green', 'red', 'yellow'],
                                position: 'outside',
                                display: true
                              }
                            },
                            scales: {
                              xAxes: [{
                                gridLines: {
                                  display: false
                                },
                                stacked: true,
                              },
                            ]
                            },
                          }}
                          />
                          </Card>
                        </div>
                      )
                    })
                  }
              </Slider>
              </div>
            </Content>
        </Fragment>
    )
}

export default Dashboard