import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col, Card, Typography, Breadcrumb, Layout, Button, message, Table, Input, Space } from 'antd'
import {Link,Redirect} from 'react-router-dom';
import Axios from 'axios';
import {url} from '../../config/global'
import { UploadOutlined, CaretDownOutlined, ReloadOutlined} from '@ant-design/icons'
import moment from 'moment'
import fileDownload from 'js-file-download'

const { Title } = Typography
const { Search } = Input
const { Content } = Layout

function WipService() {

    const [keyword, setKeyword] = useState('')
    const [wipService, setWipService] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState({
        sort: 'description',
        order: 'ascend'
    })

    const handleSearch = (value) => {
        setKeyword(value)
        sessionStorage.setItem('keyword', value)
        setPage(1)

        if (value === '') {
            getData()
        }
    }

    useEffect(() => {
        getData()
    }, [keyword, page, sort])

    const getData = () => {
        setLoading(true)
        Axios.get(`${url}/wip-service-upload`, {
            params: {
                keyword,
                columns: [
                    'po_number'
                ],
                perpage: 10,
                page,
                sort: sort.sort,
                order: sort.order
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            setWipService(res.data)
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current)
        setSort({
            sort: sorter.field,
            order: sorter.order
        })
    }

    const downloadTemplate = () => {
        Axios.get(`${url}/wip-service-download`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            },
            responseType: 'blob'
        }).then(res => {
            fileDownload(res.data, 'template_wip_import.xlsx')
        })
    }
    
    if (!sessionStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    const columns = [
        {
            title: 'Upload Date',
            dataIndex: 'created_at',
            sorter: true,
            render: (created_at) => {
                return moment(created_at).format('LL')
            }
        },
        {
            title: 'PO Number',
            dataIndex: 'po_number',
            sorter: true
        },
    ]

    if (!sessionStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card>
                        <Title level={4}>Upload WIP Service</Title>
                            <Row type="flex" justify="left">
                                <Breadcrumb>
                                    <Breadcrumb.Item>WIP Service</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                    </Card>
                </Col>
            </Row>

            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Row type="flex" gutter={[10, 20]} justify="space-around">
                                <Col xs={24} md={12} lg={18}>
                                    <Space>
                                        <Link to="/wip-service/upload">
                                            <Button type="primary"><UploadOutlined />Upload</Button>
                                        </Link>
                                            <Button type="primary" onClick={downloadTemplate}><CaretDownOutlined />Download Template</Button>
                                            <Button type="primary" onClick={getData}><ReloadOutlined />Refresh</Button>
                                    </Space>
                                    {/* <Upload {...uploadProps}>
                                        <Button>
                                            Upload Invoice <UploadOutlined />
                                        </Button>
                                    </Upload> */}
                                </Col>
                                <Col xs={24} md={12} lg={6} className="align-right">
                                    <Search
                                        placeholder="Search..."
                                        onSearch={(value) => handleSearch(value)}
                                    />
                                </Col>
                                <Col xs={24}>
                                    <Table
                                    columns={columns}
                                    dataSource={wipService.data}
                                    rowKey={(data) => data._id}
                                    onChange={handleTableChange}
                                    pagination={{ pageSize: 10, current: page, total: wipService.total, showSizeChanger: false }}
                                    loading={loading}
                                    />   
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    )
}

export default WipService
