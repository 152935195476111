import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  Typography,
  Layout,
  Row,
  Col,
  Breadcrumb,
  Input,
  Table,
  message,
  Modal,
  Button,
  Tooltip,
} from "antd";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
import moment from "moment";
import { DownloadOutlined, PrinterOutlined } from "@ant-design/icons";
import fileDownload from "js-file-download";

const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;

function ShoppingHistory() {
  const [keyword, setKeyword] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "t_shpm",
    order: "asc",
  });
  const [rowDetails, setRowDetails] = useState([]);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [shoppingDetails, setShoppingDetails] = useState([]);
  const [loadingRow, setLoadingRow] = useState([]);

  const handleSearch = (value) => {
    setKeyword(value);
    sessionStorage.setItem("keyword", value);
    setPage(1);

    if (value === "") {
      getData();
    }
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/shopping-scan`, {
      params: {
        keyword,
        columns: ["shipment_number", "customer_name", "delivery_note"],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const columns = [
    {
      title: "Shipment Number",
      dataIndex: "shipment_number",
      sorter: true,
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      sorter: true,
    },
    {
      title: "Delivery Note",
      dataIndex: "delivery_note",
      sorter: true,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      sorter: true,
      render: (created_at) => moment(created_at).format("DD MMMM YYYY"),
    },
    {
      title: "",
      dataIndex: "_id",
      sorter: false,
      render: (id, data) => {
        const isLoadingRow = loadingRow.find((x) => x.id === id);
        return (
          <>
            <Tooltip title="Download">
              <Button
                loading={isLoadingRow ? isLoadingRow.loading : false}
                onClick={() => handleDownload(id, data.shipment_number)}
                icon={<DownloadOutlined />}
                style={{ marginRight: ".5em" }}
              />
            </Tooltip>
            <Tooltip title="Print">
              <Button
                loading={isLoadingRow ? isLoadingRow.loadingPrint : false}
                onClick={() => handlePrint(id, data.shipment_number)}
                icon={<PrinterOutlined />}
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  const handleDownload = (id, shipment_number) => {
    const newRow = [...loadingRow];
    const findRow = newRow.find((x) => x.id === id);
    if (findRow) {
      findRow.loading = true;
      setLoadingRow(newRow);
    } else {
      setLoadingRow([
        ...loadingRow,
        {
          id,
          loading: true,
        },
      ]);
    }

    Axios.post(
      `${url}/shopping-scan/download`,
      {
        id,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        responseType: "blob",
      }
    )
      .then((res) => {
        fileDownload(res.data, `${shipment_number}.xlsx`);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingRow([
          ...loadingRow,
          {
            id,
            loading: false,
          },
        ]);
      });
  };

  const handlePrint = (id) => {
    setLoadingRow([
      ...loadingRow,
      {
        id,
        loadingPrint: true,
      },
    ]);
    Axios.get(`${url}/shopping-scan/print`, {
      params: {
        id,
        user: sessionStorage.getItem("fullname"),
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    })
      .then((res) => {
        // fileDownload(res.data, `${shipment_number}.pdf`);
        var blob = new Blob([res.data], { type: "application/pdf" });
        var blobURL = URL.createObjectURL(blob);
        window.open(blobURL);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingRow([
          ...loadingRow,
          {
            id,
            loadingPrint: false,
          },
        ]);
      });
  };

  const handleChangeDetails = (data) => {
    const index = data[data.length - 1];
    setRowDetails([index]);
    if (index) {
      getShoppingDetails(index);
    }
  };

  const getShoppingDetails = (id) => {
    setLoadingDetail(true);
    Axios.get(`${url}/shopping-scan/details/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setShoppingDetails(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const getDataDetails = () => {
    const columns = [
      { title: "Item Code", dataIndex: "item_code", key: "item_code" },
      { title: "Job Number", dataIndex: "job_no", key: "job_no" },
      { title: "Qty", dataIndex: "qty", key: "qty" },
      { title: "Lot Number", dataIndex: "lot_number", key: "lot_number" },
    ];

    return (
      <Table
        columns={columns}
        rowKey={(data, index) => index}
        loading={loadingDetail}
        dataSource={shoppingDetails}
        pagination={false}
      />
    );
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card>
            <Title level={4}>Shoping History</Title>
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>Shoping History</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="end">
                <Col className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    columns={columns}
                    dataSource={users.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: users.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                    expandable={{
                      expandedRowRender: getDataDetails,
                      onExpandedRowsChange: (data) => handleChangeDetails(data),
                      expandedRowKeys: rowDetails,
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default ShoppingHistory;
