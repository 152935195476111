import React, { Fragment, useState, useEffect } from 'react'
import { Form, Input, Button, Select, Layout, Row, Col, Card, Breadcrumb, Typography, message } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import Axios from 'axios';
import { url } from '../../config/global';

const { Title } = Typography
const { Option } = Select;
const { Content } = Layout

const layout = {
    labelCol: {
        lg: 8,
        md: 10
    },
    wrapperCol: {
        lg: 8,
        md: 14
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
}

function EditItemGroup(props) {

    const [data, setData] = useState({
        part_number_internal: '',
        part_number_external: '',
        part_name: '',
        min_stock: '',
        max_stock: '',
        groups: [],
        group_id: '',
        group_name: '',
        address: '',
        div: '',
        snp: ''
    })

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getItemGroup()
    }, [])

    const getItemGroup = () => {
        Axios.get(`${url}/item-group/${props.match.params.id}`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            setData({
                ...data,
                part_number_internal: res.data.data.part_number_internal,
                part_number_external: res.data.data.part_number_external,
                part_name: res.data.data.part_name,
                min_stock: res.data.data.min_stock,
                max_stock: res.data.data.max_stock,
                group_id: res.data.data.group_id,
                group_name: res.data.data.group_name,
                address: res.data.data.address,
                div: res.data.data.div,
                snp: res.data.data.snp,
            })
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status === 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        })
    }

    const handleChange = (name, value) => {
        setError(null)
        setData({
            ...data,
            [name]: value
        })
    }

    const handleChangeSelect = (name, value) => {
        setError(null)
        setData({
            ...data,
            [`${name}_id`]: value.value,
            [`${name}_name`]: value.label
        })
    }

    const getGroup = async (keyword) => {
        await Axios.get(`${url}/group/list`, {
            params: {
                keyword
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            setData({
                ...data,
                groups: res.data.data
            })
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status === 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        })
    }

    const onSaveData = () => {

        setLoading(true)

        Axios.post(`${url}/item-group/${props.match.params.id}`, {
            part_number_internal: data.part_number_internal,
            part_number_external: data.part_number_external,
            part_name: data.part_name,
            min_stock: data.min_stock,
            max_stock: data.max_stock,
            group_id: data.group_id,
            group_name: data.group_name,
            address: data.address,
            div: data.div,
            snp: data.snp,
        }, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            message.success(res.data.message)
            props.history.push('/item-group')
        }).catch(err => {
            console.log(err.response)
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status === 422) {
                    setError(err.response.data.errors)
                }
                if (err.response.status === 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleReset = () => {
        setData({
            part_number_internal: '',
            part_number_external: '',
            part_name: '',
            min_stock: '',
            max_stock: '',
            group_id: '',
            address:'',
            div:'',
            snp:'',
        })
    }

    if (!sessionStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card>
                        <Title level={4}>Edit Item Group</Title>
                        <Row type="flex" justify="left">
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/item-group">Back</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>Edit Item Group</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Form 
                            {...layout}
                            name="control-hooks"
                            onFinish={onSaveData}
                            >
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        required
                                        label="Part Number Internal"
                                        validateStatus={error && error.part_number_internal ? 'error' : false }
                                        help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                                    >
                                        <Input value={data.part_number_internal} onChange={(e) => handleChange('part_number_internal', e.target.value)} />
                                    </Form.Item>
                                    <Form.Item
                                        required
                                        label="Part Name"
                                        validateStatus={error && error.part_name ? 'error' : false }
                                        help={error && error.part_name ? error.part_name[0] : false }
                                    >
                                        <Input value={data.part_name} onChange={(e) => handleChange('part_name', e.target.value)} />
                                    </Form.Item>
                                    <Form.Item
                                        required
                                        label="Max Stock"
                                        validateStatus={error && error.max_stock ? 'error' : false }
                                        help={error && error.max_stock ? error.max_stock[0] : false }
                                    >
                                        <Input value={data.max_stock} onChange={(e) => handleChange('max_stock', e.target.value)} />
                                    </Form.Item>
                                    <Form.Item
                                        required
                                        label="Group"
                                        validateStatus={error && error.group_id ? 'error' : false }
                                        help={error && error.group_id ? error.group_id[0] : false }
                                    >
                                        <Select 
                                            labelInValue
                                            onChange={(value) => handleChangeSelect('group', value)}
                                            onFocus={() => getGroup('')}
                                            showSearch 
                                            onSearch={(value) => getGroup(value)} 
                                            filterOption={false}
                                            value={{ key: data.group_id, label: data.group_name }}
                                        >
                                            {
                                                data.groups && data.groups.map(group => {
                                                    return <Option value={group._id} key={group._id}>{group.name}</Option>
                                                })
                                            }

                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        required
                                        label="Part Number External"
                                        validateStatus={error && error.part_number_external ? 'error' : false }
                                        help={error && error.part_number_external ? error.part_number_external[0] : false }
                                    >
                                        <Input value={data.part_number_external} onChange={(e) => handleChange('part_number_external', e.target.value)} />
                                    </Form.Item>
                                    <Form.Item
                                        required
                                        label="Min Stock"
                                        validateStatus={error && error.min_stock ? 'error' : false }
                                        help={error && error.min_stock ? error.min_stock[0] : false }
                                    >
                                        <Input value={data.min_stock} onChange={(e) => handleChange('min_stock', e.target.value)} />
                                    </Form.Item>
                                    <Form.Item
                                        required
                                        label="Address"
                                        validateStatus={error && error.address ? 'error' : false }
                                        help={error && error.address ? error.address[0] : false }
                                    >
                                        <Input value={data.address} onChange={(e) => handleChange('address', e.target.value)} />
                                    </Form.Item>
                                    <Form.Item
                                        required
                                        label="DIV"
                                        validateStatus={error && error.div ? 'error' : false }
                                        help={error && error.div ? error.div[0] : false }
                                    >
                                        <Input value={data.div} onChange={(e) => handleChange('div', e.target.value)} />
                                    </Form.Item>
                                    <Form.Item
                                        required
                                        label="SNP"
                                        validateStatus={error && error.snp ? 'error' : false }
                                        help={error && error.snp ? error.snp[0] : false }
                                    >
                                        <Input value={data.snp} onChange={(e) => handleChange('snp', e.target.value)} />
                                    </Form.Item>
                                    <Form.Item {...tailLayout}>
                                        <Button loading={loading} type="primary" htmlType="submit" className="mr-button">
                                            Submit
                                        </Button>
                                        <Button htmlType="reset" onClick={handleReset}>
                                            Reset
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row> 
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    )
}

export default EditItemGroup