import React, { Fragment, useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Breadcrumb,
  Layout,
  Button,
  message,
  Table,
  Input,
  Space,
} from "antd";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import { ReloadOutlined, SendOutlined } from "@ant-design/icons";
import { url } from "../../config/global";

const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;

function Import(props) {
  const [keyword, setKeyword] = useState("");
  const [keywordDetails, setKeywordDetails] = useState("");
  const [imports, setImports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "number",
    order: "ascend",
  });
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [rowDetails, setRowDetails] = useState([]);
  const [importDetails, setImportDetails] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [importDetailsTotal, setTotalImportDetails] = useState(0);
  const [loadingReceiving, setLoadingReceiving] = useState([]);

  const handleSearch = (value, type) => {
    if (type === "details") {
      setPageDetail(1);
      setKeywordDetails(value);
      if (value === "") {
        getReceiveImportDetails();
      }
    } else {
      setKeyword(value);
      sessionStorage.setItem("keyword", value);
      if (value === "") {
        getData();
      }
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRow) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRow);
    },
    getCheckboxProps: (record) => ({
      disabled: record.status === 1, // Column configuration not to be checked
      status: record.status,
    }),
    selectedRowKeys,
  };

  useEffect(() => {
    getData();
  }, [page, sort, keyword]);

  useEffect(() => {
    if (selectedId) {
      getReceiveImportDetails();
    }
  }, [keywordDetails, pageDetail, perPageDetail]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/warehouse-receiving-import`, {
      params: {
        keyword: sessionStorage.getItem("keyword")
          ? sessionStorage.getItem("keyword")
          : keyword,
        columns: ["number"],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setImports(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeDetails = (data) => {
    const index = data[data.length - 1];
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);
      getReceiveImportDetails(data2[0]);
    }
  };

  const getReceiveImportDetails = (id = selectedId) => {
    setLoadingDetail(true);
    Axios.get(`${url}/warehouse-receiving-import/details/${id}`, {
      params: {
        keyword: keywordDetails,
        columns: ["number", "part_number", "po_number"],
        perpage: perPageDetail,
        page: pageDetail,
      },

      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setImportDetails(res.data.data);
        setTotalImportDetails(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const saveData = (number) => {
    // setLoading(true);
    setLoadingReceiving([
      ...loadingReceiving,
      {
        id: number,
        loading: true,
      },
    ]);

    if (selectedRows.length > 0) {
      Axios.post(
        `${url}/warehouse-receiving-import`,
        {
          ids: selectedRows,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
        .then((res) => {
          message.success(res.data.message);
          getData();
          getReceiveImportDetails();
          setSelectedRowKeys([]);
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data.message);
          } else {
            message.error("Backend not yet Started");
          }
        })
        .finally(() => {
          setLoadingReceiving([]);
        });
    } else {
      message.error("Please choose at least one item");
    }

    // setLoading(false);
  };
  console.log(loadingReceiving);
  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChangeDetailsTable = (pagination, filter, sorter) => {
    setPageDetail(pagination.current);
    setPerPageDetail(pagination.pageSize);
  };

  const getImportDetails = () => {
    const columns = [
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (status) => {
          return (
            <img
              width="20"
              src={
                status === 1
                  ? require("../../assets/img/checked.png")
                  : require("../../assets/img/danger.png")
              }
            />
          );
        },
      },
      {
        title: "Receipt Number",
        dataIndex: "receipt_number",
        key: "receipt_number",
      },
      { title: "Po Number", dataIndex: "po_number", key: "po_number" },
      { title: "Part Number", dataIndex: "part_number", key: "part_number" },
      { title: "Description", dataIndex: "description", key: "description" },
      {
        title: "Total Meter",
        dataIndex: "totalmeter_formatted",
        align: "right",
        key: "totalmeter_formatted",
      },
      {
        title: "Qty",
        dataIndex: "quantity_formatted",
        align: "right",
        key: "quantity_formatted",
      },
      {
        title: "Unit Price",
        dataIndex: "unit_price_formatted",
        align: "right",
        key: "unit_price_formatted",
      },
      {
        title: "Amount",
        dataIndex: "amount_formatted",
        align: "right",
        key: "amount_formatted",
      },
      { title: "Container", dataIndex: "container", key: "container" },
      {
        title: "Packages",
        dataIndex: "packages_formatted",
        align: "right",
        key: "packages_formatted",
      },
      {
        title: "Net Weight",
        dataIndex: "netweight_formatted",
        align: "right",
        key: "netweight_formatted",
      },
      {
        title: "Gross Weight",
        dataIndex: "grossweight_formatted",
        align: "right",
        key: "grossweight_formatted",
      },
    ];

    return (
      <>
        <Row justify="end" gutter={[0, 20]}>
          <Col>
            <Input.Search
              placeholder="Search details..."
              defaultValue={keywordDetails}
              onSearch={(value) => handleSearch(value, "details")}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Table
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              scroll={{ x: 1000 }}
              columns={columns}
              rowKey={(data, index) => index}
              loading={loadingDetail}
              dataSource={importDetails}
              pagination={{
                pageSize: perPageDetail,
                current: pageDetail,
                total: importDetailsTotal,
                showSizeChanger: true,
                style: {
                  marginTop: "2rem",
                  marginBottom: 0,
                },
              }}
              onChange={handleChangeDetailsTable}
            />
          </Col>
        </Row>
      </>
    );
  };

  const columns = [
    {
      title: "Invoice",
      dataIndex: "number",
      sorter: true,
      color: "",
      sortOrder: "ascend",
    },

    {
      title: "Status",
      dataIndex: "status",
      sorter: false,
      render: (status, data) => {
        return (
          <img
            width="20"
            src={
              data.total_invoice === data.total_invoice_received
                ? require("../../assets/img/checked.png")
                : require("../../assets/img/danger.png")
            }
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "number",
      key: "number",
      align: "center",
      sorter: false,
      fixed: "right",
      width: 150,
      render: (number, data) => {
        const findLoading = loadingReceiving.find((x) => x.id === number);
        return (
          <Button
            loading={findLoading ? findLoading.loading : false}
            onClick={() => saveData(number)}
            disabled={data.total_invoice === data.total_invoice_received}
            size="small"
            type="primary"
          >
            <SendOutlined />
            Receive
          </Button>
        );
      },
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card>
            <Title level={4}>Receiving Import</Title>
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>Receiving Import</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    <Button type="primary" onClick={getData}>
                      <ReloadOutlined />
                      Refresh
                    </Button>
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                    defaultValue={sessionStorage.getItem("keyword")}
                  />
                </Col>
                <Col xs={24}>
                  <Table
                    scroll={{ x: 100 }}
                    columns={columns}
                    expandable={{
                      expandedRowRender: getImportDetails,
                      onExpandedRowsChange: (data) => handleChangeDetails(data),
                      expandedRowKeys: rowDetails,
                    }}
                    dataSource={imports.data}
                    rowKey={(data) => `${data.number}`}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: imports.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                    sort
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default Import;
