import React, { Fragment, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  Typography,
  message,
  Checkbox,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";

const { Title } = Typography;
const { Option } = Select;
const { Content } = Layout;
const { TextArea } = Input;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 8,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function CreateMatrixKanban(props) {
  const [data, setData] = useState({
    customer_code: "",
    customer_name: "",
    start: "",
    length: "",
    part_number: true,
  });

  const [customers, setCustomers] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const getCustomer = (keyword) => {
    setLoadingCustomer(true);
    Axios.get(`${url}/matrix-kanban/customers`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCustomers(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingCustomer(false);
      });
  };

  const handleChangeSelect = (name, value) => {
    setError(null);
    setData({
      ...data,
      [`${name}_code`]: value.value,
      [`${name}_name`]: value.label,
    });
  };

  const onSaveData = () => {
    setLoading(true);

    Axios.post(
      `${url}/matrix-kanban`,
      {
        customer_code: data.customer_code,
        customer_name: data.customer_name,
        start: data.start,
        length: data.length,
        part_number: data.part_number,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/matrix-kanban");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      customer_code: "",
      customer_name: "",
      start: "",
      length: "",
    });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card>
            <Title level={4}>Add Matrix Kanban</Title>
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/matrix-kanban">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Add Matrix Kanban</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} name="control-hooks" onFinish={onSaveData}>
                <Form.Item
                  label="Customer"
                  required
                  validateStatus={
                    error && error.customer_code ? "error" : false
                  }
                  help={
                    error && error.customer_code
                      ? error.customer_code[0]
                      : false
                  }
                >
                  <Select
                    labelInValue
                    onChange={(value) => handleChangeSelect("customer", value)}
                    onFocus={() => getCustomer("")}
                    showSearch
                    onSearch={(value) => getCustomer(value)}
                    filterOption={false}
                    value={{
                      key: data.customer_code,
                      label: data.customer_name,
                    }}
                    loading={loadingCustomer}
                  >
                    {customers &&
                      customers.map((customer) => {
                        return (
                          <Select.Option
                            key={customer.code}
                            value={customer.code}
                          >
                            {`${customer.code} - ${customer.name}`}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  required
                  label="Start"
                  validateStatus={error && error.start ? "error" : false}
                  help={error && error.start ? error.start[0] : false}
                >
                  <Input
                    value={data.start}
                    onChange={(e) => handleChange("start", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Length"
                  validateStatus={error && error.length ? "error" : false}
                  help={error && error.length ? error.length[0] : false}
                >
                  <Input
                    value={data.length}
                    onChange={(e) => handleChange("length", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label="Part Number"
                  validateStatus={error && error.part_number ? "error" : false}
                  help={
                    error && error.part_number ? error.part_number[0] : false
                  }
                >
                  <Checkbox
                    checked={data.part_number}
                    onChange={(e) =>
                      handleChange("part_number", e.target.checked)
                    }
                  >
                    Yes
                  </Checkbox>
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="mr-button"
                  >
                    Submit
                  </Button>
                  <Button htmlType="reset" onClick={handleReset}>
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default CreateMatrixKanban;
