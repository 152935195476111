import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col, Card, Typography, Breadcrumb, Layout, message, Table, Input, DatePicker, Button, Space } from 'antd'
import {Link, Redirect} from 'react-router-dom';
import moment from 'moment'
import Axios from 'axios';
import {url} from '../../config/global'
import { CaretDownOutlined, FileOutlined } from '@ant-design/icons'
import { queryByTestId } from '@testing-library/react';
import fileDownload from 'js-file-download'

const { Title } = Typography
const { Search } = Input
const { Content } = Layout
const { RangePicker} = DatePicker

function ViewDeliveryNote(props) {

    // const [data, setData] = useState({
    //     start_date: moment().startOf('month'),
    //     end_date: moment().endOf('month')
    // })

    const [month, setMonth] = useState(moment().startOf('month'))
    const [keyword, setKeyword] = useState('')
    const [deliveryNote, setDeliveryNote] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState({
        sort: 'part_number',
        order: 'ascend'
    })


    const handleSearch = (value) => {
        setKeyword(value)
        sessionStorage.setItem('keyword', value)
        setPage(1)

        if (value === '') {
            getData()
        }
    }

    const handleChangeDate = (value) => {
        if (value) {
            setMonth(value)
        } else {
            setMonth(undefined)
        }
    }

    const handleTableChange = (pagination, filters, sorter, extra) => {
        
        setPage(pagination.current)
        setSort({
            sort: sorter.field,
            order: sorter.order
        })
        //setPageSize(pagination.pageSize)
    }

    useEffect(() => {
        getData()
    }, [keyword, page, sort, month])

    const getData = () => {
        setLoading(true)
        Axios.get(`${url}/delivery-note-report`, {
            params: {
                keyword: sessionStorage.getItem('keyword') ? sessionStorage.getItem('keyword') : keyword,
                columns: [
                    'part_number',
                    'warehouse',
                    'supplier_name'
                ],
                perpage: 10,
                page,
                sort: sort.sort,
                order: sort.order,
                start_date: moment(month.startOf('month')).format("YYYY-MM-DD"),
                end_date: moment(month.endOf('month')).format("YYYY-MM-DD"),
                part_number: props.match.params.id
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
            
        }).then(res => {
            setDeliveryNote(res.data)
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const downloadReport = () => {
        Axios.get(`${url}/delivery-note/export`, {
            params: {
                keyword: sessionStorage.getItem('keyword') ? sessionStorage.getItem('keyword') : keyword,
                columns: [
                    'part_number',
                    'warehouse',
                    'supplier_name'
                ],
                perpage: 10,
                page,
                sort: sort.sort,
                order: sort.order,
                start_date: moment(month.startOf('month')).format("YYYY-MM-DD"),
                end_date: moment(month.endOf('month')).format("YYYY-MM-DD"),
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            },
            responseType: 'blob'
        }).then(res => {
            fileDownload(res.data, 'report_delivery_note.xlsx')
        })
    }

    const columns = [
        {
            title: 'Supplier',
            dataIndex: 'supplier_name',
            sorter: true
        },
        {
            title: 'Part ERP',
            dataIndex: 'part_number_erp',
            sorter: true
        },
        {
            title: 'Part No',
            dataIndex: 'part_number',
            sorter: true
        },
        {
            title: 'Description',
            dataIndex: 'part_name',
            sorter: true
        },
        {
            title: 'Date',
            dataIndex: 'date',
            sorter: true
        },
        {
            title: 'Time',
            dataIndex: 'time',
            sorter: true
        },
        {
            title: 'Schedule',
            dataIndex: 'qty_plan',
            align: 'right',
            sorter: true
        },
        {
            title: 'Actual',
            dataIndex: 'qty_actual',
            align: 'right',
            sorter: true
        },
        {
            title: 'Outstanding',
            dataIndex: 'qty_outstanding',
            align: 'right',
            sorter: true
        },
    ]

    if (!sessionStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card>
                        <Title level={4}>Delivery Note</Title>
                        <Row type="flex" justify="left">
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/delivery-note">Back</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>Delivery Note</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Row type="flex" gutter={[10, 20]} justify="space-between">
                                <Col md={12}>
                                    <Space>
                                        <DatePicker 
                                        onChange={handleChangeDate}
                                        value={month}
                                        picker="month"
                                        format="MMMM YYYY"
                                        />

                                        <Search
                                        placeholder="Search"
                                        onSearch={(value) => handleSearch(value)}
                                        defaultValue={sessionStorage.getItem('keyword')}
                                        />
                                        
                                    </Space>
                                </Col>
                                <Col xs={24} md={12} lg={6} className="align-right">
                                    <Button type="primary" onClick={downloadReport}><FileOutlined />Export to Excel</Button>
                                </Col>

                                <Col xs={24}>
                                    <Table 
                                    scroll={{x: 1000}}
                                    columns={columns}
                                    dataSource={deliveryNote.data}
                                    rowKey={(data, index) => index}
                                    pagination={{ pageSize: 10, current: page, total: deliveryNote.total, showSizeChanger: false }}
                                    loading={loading}
                                    onChange={handleTableChange}
                                    />   
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    )
}

export default ViewDeliveryNote