import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Breadcrumb,
  Layout,
  Button,
  message,
  Table,
  Space,
  Input,
  Modal,
  Select,
  Radio,
  DatePicker,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
import {
  ReloadOutlined,
  ScanOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";
import moment from "moment";
const _ = require("underscore");

const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;

function Outgoing(props) {
  const [data, setData] = useState({
    po_number: "",
    dn_number: "",
    t_qhnd: "",
    stock_outgoing: "",
    part_number: "",
    part_name: "",
    warehouse: "",
    pcs_kanban: 0,
    scan_by: "",
    date: moment(new Date()),
  });
  const [keyword, setKeyword] = useState("");
  const [outgoing, setOutgoing] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("label");
  const [state, setState] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "t_item",
    order: "ascend",
  });
  const [dataDisabled, setDataDisabled] = useState({
    po_number: false,
    dn_number: true,
    part_number: true,
    stock_outgoing: true,
    qty: true,
  });

  const [part, setPart] = useState({
    part_number: undefined,
    part_name: undefined,
  });
  const [parts, setParts] = useState([]);

  const handleSearch = (value) => {
    setKeyword(value);
    sessionStorage.setItem("keyword", value);
    setPage(1);

    if (value === "") {
      getData();
    }
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const showModal = () => {
    setState({
      visible_outgoing: true,
    });
  };

  const DnRef = useRef(null);
  const QtyRef = useRef(null);

  useEffect(
    () => {
      getData();
      handleGetParts("");
    },
    [keyword, page, sort],
    [part.part_number]
  );

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/warehouse-outgoing`, {
      params: {
        keyword: sessionStorage.getItem("keyword")
          ? sessionStorage.getItem("keyword")
          : keyword,
        columns: [
          "t_item",
          "t_dsca",
          "t_nama",
          //data.item && data.item.t_dsca
        ],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setOutgoing(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const scanData = async (e, name) => {
    e.preventDefault();

    const dataDN = data.dn_number.split(";");

    const DNdelimeter = dataDN.length;

    if (DNdelimeter < 6 && name === "dn_number") {
      message.error("Bad QR Code for DN Number");
    } else {
      Axios.get(`${url}/warehouse-outgoing-scan`, {
        params: {
          po_number: data.po_number,
          dn_number: dataDN[5],
          part_number: dataDN[0],
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          if (res.data.data) {
            if (name === "dn_number") {
              setDataDisabled({
                ...dataDisabled,
                dn_number: true,
                qty: false,
              });

              setData({
                ...data,
                stock_outgoing: dataDN[1],
                part_name: res.data.data.part_name,
                part_number: res.data.data.part_number,
                pcs_kanban: res.data.data.pcs_kanban,
              });

              QtyRef.current.focus();
            } else {
              setDataDisabled({
                ...dataDisabled,
                po_number: true,
                dn_number: false,
              });

              DnRef.current.focus();
            }
          } else {
            message.error("Data not found!");
          }
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data.message);
          } else {
            message.error("Backend not yet Started");
          }
        });
    }
  };

  const handleGetParts = async (value) => {
    Axios.get(`${url}/warehouse-outgoing/list`, {
      params: {
        keyword: value,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.data) {
          setParts(res.data.data);
        } else {
          message.error("Data not found!");
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      });
  };

  //action saat memilih part_number
  const handleSetData = (value) => {
    const selectedPart = parts.find((part_no) => part_no.part_number === value);
    setData({
      ...data,
      part_number: selectedPart.part_number,
      part_name: selectedPart.part_name,
      stock_outgoing: 1,
      pcs_kanban: selectedPart.pcs_kanban,
    });
  };

  const saveData = () => {
    setLoading(true);

    const dataDN = data.dn_number.split(";");

    const part_number = value === "label" ? dataDN[0] : data.part_number;
    if (data.stock_outgoing % parseInt(data.pcs_kanban) === 0) {
      Axios.post(
        `${url}/warehouse-outgoing`,
        {
          // po_number: data.po_number,
          dn_number: dataDN[5],
          warehouse: data.warehouse,
          scan_by: value,
          //part_number: dataDN[0],
          part_number,
          part_name: data.part_name,
          stock_outgoing: data.stock_outgoing,
          date: moment(data.date).format("YYYY-MM-DD"),
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
        .then((res) => {
          message.success(res.data.message);
          handleReset();
          getData();
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data.message);
          } else {
            message.error("Backend not yet Started");
          }
        })
        .finally(() => {
          setState({
            ...state,
            visible_outgoing: false,
          });
          setLoading(false);
        });
    } else {
      message.error("Qty is not a multiple of SNP");
      setLoading(false);
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleCancel = (e) => {
    setState({
      visible_outgoing: false,
    });
  };

  const handleReset = () => {
    setData({
      ...data,
      po_number: "",
      dn_number: "",
      part_name: "",
      stock_outgoing: "",
      part_number: "",
    });

    setDataDisabled({
      ...setDataDisabled,
      po_number: false,
      dn_number: true,
      stock_outgoing: true,
      qty: true,
    });
  };

  const columns = [
    {
      title: "Part Number",
      dataIndex: "t_item",
      sorter: true,
    },
    {
      title: "Part Name",
      dataIndex: "t_dsca",
      sorter: true,
    },
    {
      title: "Onhand",
      dataIndex: "t_qhnd",
      align: "right",
      sorter: true,
    },
    {
      title: "Outgoing",
      dataIndex: "stock_outgoing",
      align: "right",
      sorter: false,
      render: (stock_outgoing, data) => (
        <Link to={`/scan-outgoing-wh/view/${data.t_item.replace(/ /g, "")}`}>
          {stock_outgoing}
        </Link>
      ),
    },
    {
      title: "Current Stock",
      dataIndex: "stock_current",
      align: "right",
      sorter: false,
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const { Option } = Select;

  function onChange(value) {
    setData({
      ...data,
      warehouse: value,
    });
  }

  const radioChange = (e) => {
    console.log(e.target.value);
    setValue(e.target.value);
    setData({
      ...data,
      dn_number: "",
      stock_outgoing: "",
      part_name: "",
      pcs_kanban: 0,
    });
  };

  function onFocus() {
    console.log("focus");
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  return (
    <Fragment>
      <Modal
        title="Scan Outgoing"
        visible={state.visible_outgoing}
        onCancel={handleCancel}
        destroyOnClose={handleCancel}
        footer={[
          <Button key="reset" onClick={handleReset}>
            Reset
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={saveData}
          >
            Submit
          </Button>,
        ]}
      >
        <Row style={{ marginTop: 20 }}>
          <Col span={8} style={{ fontWeight: "bold" }}>
            <label>Date</label>
          </Col>
          <Col span={16}>
            <DatePicker
              placeholder="Date"
              value={data.date}
              onChange={(value) => handleChange("date", value)}
              format="DD MMMM YYYY"
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col span={8} style={{ fontWeight: "bold" }}>
            <label>Warehouse</label>
          </Col>
          <Col span={16}>
            <Select
              //value={data.warehouse}
              showSearch
              style={{ width: 200 }}
              placeholder="Select Warehouse"
              optionFilterProp="children"
              onChange={onChange}
              onFocus={onFocus}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="WHRM01">WHRM 01</Option>
              <Option value="WHRM02">WHRM 02</Option>
            </Select>
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col span={8} style={{ fontWeight: "bold" }}>
            <label>Scan by :</label>
          </Col>
          <Col span={16}>
            <Radio.Group onChange={radioChange} defaultValue="label">
              <Radio value="label" checked={value === "label" ? true : false}>
                Label
              </Radio>
              <Radio value="manual" checked={value === "manual" ? true : false}>
                Manual
              </Radio>
            </Radio.Group>
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col span={8} style={{ fontWeight: "bold" }}>
            {value === "label" && <label>Kanban</label>}
            {value === "manual" && <label>Part Number</label>}
          </Col>
          <Col span={16}>
            {value === "label" && (
              <Input
                onPressEnter={(e) => scanData(e, "dn_number")}
                prefix={<QrcodeOutlined />}
                //disabled={dataDisabled.dn_number}
                onChange={(e) => handleChange("dn_number", e.target.value)}
                value={data.dn_number}
                ref={DnRef}
              />
            )}
            {value === "manual" && (
              <Select
                showSearch
                style={{ width: 300 }}
                placeholder="Part Number"
                onFocus={() => handleGetParts("")}
                onSearch={(value) => handleGetParts(value)}
                onChange={(value) => handleSetData(value)}
                value={data.part_number}
              >
                {parts &&
                  parts.map((part) => {
                    return (
                      <Select.Option
                        key={part.part_number}
                        value={part.part_number}
                      >
                        {part.part_number}
                      </Select.Option>
                    );
                  })}
              </Select>
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col span={8} style={{ fontWeight: "bold" }}>
            <label>Part Name</label>
          </Col>
          <Col span={16}>
            <Input
              disabled
              onChange={(e) => handleChange("part_name", e.target.value)}
              value={data.part_name}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col span={8} style={{ fontWeight: "bold" }}>
            <label>Quantity</label>
          </Col>
          <Col span={8}>
            <Input
              //disabled={dataDisabled.qty}
              onChange={(e) => handleChange("stock_outgoing", e.target.value)}
              value={data.stock_outgoing}
              ref={QtyRef}
            />
          </Col>
        </Row>
      </Modal>

      <Row>
        <Col xs={24}>
          <Card>
            <Title level={4}>Scan Outgoing</Title>
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>Scan Outgoing</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-between">
                <Col md={12}>
                  <Space>
                    <Button type="primary" onClick={showModal}>
                      <ScanOutlined />
                      Scan
                    </Button>
                    <Button type="primary" onClick={getData}>
                      <ReloadOutlined />
                      Refresh
                    </Button>
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={8} className="align-right">
                  <Search
                    placeholder="Search by Part Number"
                    onSearch={(value) => handleSearch(value)}
                    defaultValue={sessionStorage.getItem("keyword")}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 1000 }}
                    columns={columns}
                    dataSource={outgoing.data}
                    rowKey={(data, index) => index}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: outgoing.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                    onChange={handleTableChange}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default Outgoing;
