import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col, Card, Typography, Breadcrumb, Layout, Menu, Button, message, Dropdown, Table, Upload, Input } from 'antd'
import {Redirect} from 'react-router-dom';
import Axios from 'axios';
import {url} from '../../config/global'
import { MoreOutlined, UploadOutlined, SaveOutlined} from '@ant-design/icons'
import './SalesPrice.less'
import AntNumberFormat from '../../components/AntNumberFormat';

const { Title } = Typography
const { Search } = Input
const { Content } = Layout

function TempSalesPrice(props) {

    const [data, setData] = useState({
        so_number: '',
        part_number: '',
        qty: '',
        price: ''
    })
    const [keyword, setKeyword] = useState('')
    const [error, setError] = useState('')
    const [tempSalesPrices, setTempSalesPrices] = useState([])
    const [loading, setLoading] = useState(false)
    const [fileList, setFileList] = useState([])
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState({
        sort: 'so_number',
        order: 'ascend'
    })

    const handleChange = (name, value) => {
        //setError(null)
        setData({
            ...data,
            [name]: value
        })
    }

    useEffect(() => {
        getData()
    }, [keyword, page, sort])

    const getData = () => {
        setLoading(true)
        Axios.get(`${url}/sales-price-temp`, {
            params: {
                keyword,
                columns: [
                    'so_number',
                    'part_number'
                ],
                perpage: 10,
                page,
                sort: sort.sort,
                order: sort.order
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
            
        }).then(res => {
            setTempSalesPrices(res.data)
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    sessionStorage.removeItem('token')
                }
            } 
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleSearch = (value) => {
        setKeyword(value)
    }

    const uploadProps = {
        name: 'import_file',
        action: `${url}/sales-price-temp-import`,
        fileList,
        headers: {
          authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        onChange(info) {
            setFileList([info.file])
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            setFileList([])
            getData()
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
    }

     const onSaveData = () => {

        setLoading(true)
        
        Axios.post(`${url}/sales-price-temp`,{}, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            message.success(res.data.message)
            props.history.push('/sales-price')
        }).catch(err => {
            console.log(err.response)
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status === 422) {
                    setError(err.response.data.errors)
                }
                if (err.response.status == 401) {
                    sessionStorage.removeItem('token')``
                }
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current)
        setSort({
            sort: sorter.field,
            order: sorter.order
        })
    }

    const columns = [
        {
            title: 'SO Number',
            dataIndex: 'sales',
            sorter: false,
            render: (sales) => sales && sales.so_number,
        },
        {
            title: 'Part Number',
            dataIndex: 'part_number',
            sorter: true
        },
        {
            title: 'Part Name',
            dataIndex: 'part_name',
            sorter: false
        },
        {
            title: 'Qty',
            dataIndex: 'qty',
            sorter: true,
            align: 'right'
        },
        {
            title: 'Price',
            dataIndex: 'price',
            sorter: true,
            align: 'right',
            fixed: 'right',
            render: (price, data) => {
                return data.sales && !data.sales.status ? <AntNumberFormat value={price} type="text" /> : <AntNumberFormat value={price} onChange={(e) => handleChange(console.log(e))} />
            }
        }
    ]

    if (!sessionStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card>
                        <Title level={4}>Temporary Sales Price</Title>
                            <Row type="flex" justify="left">
                                <Breadcrumb>
                                    <Breadcrumb.Item>Temporary Sales Price</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                    </Card>
                </Col>
            </Row>

            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Row type="flex" gutter={[10, 20]} justify="space-between">
                                <Col>
                                    <Upload {...uploadProps}>
                                        <Button>
                                            Upload Price <UploadOutlined />
                                        </Button>
                                    </Upload>
                                </Col>
                                <Col>
                                    <Search 
                                        placeholder="Search ..."
                                        onSearch={(value) => handleSearch(value)}
                                    />
                                </Col>

                                <Col xs={24}>
                                    <Table 
                                    scroll={{x: 1000}}
                                    columns={columns}
                                    dataSource={tempSalesPrices.data}
                                    rowKey={(data) => data._id}
                                    rowClassName={(data) => data.sales && !data.sales.status ? "row-error" : ''}
                                    pagination={{ pageSize: 10, total: tempSalesPrices.total }}
                                    loading={loading}
                                    />   

                                    {
                                        tempSalesPrices.data && tempSalesPrices.data.length > 0 && (
                                            <Button loading={loading} onClick={onSaveData} type="primary" icon={<SaveOutlined />}>Save</Button>
                                        )
                                    }
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    )
}

export default TempSalesPrice
