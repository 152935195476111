import React, { Fragment, useState, useRef } from 'react'
import { Row, Col, Card, Typography, Breadcrumb, Layout, Tabs, Button, Input, Form, Space, message } from 'antd'
import { QrcodeOutlined } from '@ant-design/icons'
import QrReader from 'react-qr-reader'
import './Wip.less'
import Axios from 'axios'
import { url } from '../../config/global'

const { Title } = Typography
const { Content } = Layout
const { TabPane } = Tabs

function Wip() {
    const layout = {
        labelCol: {
            lg: 10,
            md: 16
        },
        wrapperCol: {
            lg: 10,
            md: 16
        },
    }

    const tailLayout = {
        wrapperCol: {
            span: 16,
        },
    }

    const [tab, setTab] = useState('1')
    const [success, setSuccess] = useState(true)
    const [data, setData] = useState({
        production_order: '',
        kanban_number: '',
        qty: '',
        part_number: '',
        part_name: '',
        supplier_code: '',
        supplier_name: '',
        warehouse:''
    })
    const [loading, setLoading] = useState(false)
    const [dataDisabled, setDataDisabled] = useState({
        production_order: false,
        kanban_number: true,
        qty: true
    })

    const handleTab = (key) => {
        setTab(key)
    }

    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        })
    }

    const DnRef = useRef(null)
    const QtyRef = useRef(null)
    const scanData = async (e, name) => {
        e.preventDefault();

        const dataKanban = data.kanban_number.split(';')

        const delimeterKanban = dataKanban.length

        if (delimeterKanban < 4 && name === 'kanban_number'){
            message.error('Bad QR Code for Kanban Number')
        } else {
                Axios.get(`${url}/wip-scan`, {
                    params: {
                        production_order: data.production_order,
                        warehouse: data.warehouse,
                        // supplier_code: data.supplier_code,
                        // supplier_name: data.supplier_name,
                        kanban_number: dataKanban[2],
                        part_number: dataKanban[0],
                    },
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('token')}`
                    }
                }).then(res => {
                    if (res.data.data) {
                        if (name === 'kanban_number') {

                            setDataDisabled({
                                ...dataDisabled,
                                kanban_number: true,
                                qty: false
                            })

                            setData({
                                ...data,
                                qty: dataKanban[1],
                                supplier_code: res.data.data.supplier_code,
                                supplier_name: res.data.data.supplier_name,
                                part_name: res.data.data.part_name,
                                part_number: res.data.data.part_number,
                                warehouse: res.data.data.warehouse
                            })
                    
                            QtyRef.current.focus()
                            
                        } else {
                            setDataDisabled({
                                ...dataDisabled,
                                production_order: true,
                                kanban_number: false
                            })
                    
                            DnRef.current.focus()
                        }
                        
                    } else {
                        
                        message.error('Data not found!')
                            
                    }
                }).catch(err => {
                if (err.response) {
                    message.error(err.response.data.message)
                }
            })
        }
    }

    const saveData = () => {
        setLoading(true)

        const dataKanban = data.kanban_number.split(';')

        Axios.post(`${url}/wip`, {
            production_order: data.production_order,
            lot_number: dataKanban[2],
            supplier_code: data.supplier_code,
            supplier_name: data.supplier_name,
            part_number: dataKanban[0],
            part_number_converted: data.part_number_converted,
            part_name: data.part_name,
            warehouse: data.warehouse,
            qty: data.qty
        }, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            
            message.success(res.data.message)
            handleReset()

        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleReset = () => {
        setData({
            ...data,
            production_order: '',
            kanban_number: '',
            qty: '',
            part_number:'',
            stock_onhand:'',
            part_name: '',
            supplier_name: '',
            uom: ''
        })

        setDataDisabled({
            ...setDataDisabled,
            production_order: false,
            kanban_number: true,
            qty: true
        })
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card>
                        <Title level={4}>Scan WIP</Title>
                            <Row type="flex" justify="left">
                                <Breadcrumb>
                                    <Breadcrumb.Item>Scan WIP</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                    </Card>
                </Col>
            </Row>

            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Tabs defaultActiveKey="1" onChange={handleTab}>
                                <TabPane tab="Manual Input" key="1">
                                    <Form layout="horizontal" labelAlign="left">
                                        <Row>
                                            <Col xs={24} md={12} lg={12}>
                                                <Form.Item {...layout}
                                                    required
                                                    label="Production Order"
                                                    //className="login-input"
                                                >
                                                    <Input 
                                                        onPressEnter={(e) => scanData(e, 'production_order')}
                                                        onChange={(e) => handleChange('production_order', e.target.value)}
                                                        value={data.production_order}
                                                        disabled={dataDisabled.production_order}
                                                        prefix={<QrcodeOutlined />}
                                                    />
                                                </Form.Item>
                                                <Form.Item {...layout}
                                                    required
                                                    label="Kanban"
                                                >
                                                    <Input 
                                                        onPressEnter={(e) => scanData(e, 'kanban_number')}
                                                        onChange={(e) => handleChange('kanban_number', e.target.value)}
                                                        value={data.kanban_number}
                                                        disabled={dataDisabled.kanban_number}
                                                        prefix={<QrcodeOutlined />}
                                                        ref={DnRef}
                                                    />
                                                </Form.Item>
                                                <Form.Item {...layout}
                                                    required
                                                    label="Qty"
                                                >
                                                    <Input
                                                        //suffix={data.uom}
                                                        onChange={(e) => handleChange('qty', e.target.value)}
                                                        value={data.qty}
                                                        disabled
                                                        ref={QtyRef}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12} lg={12}>
                                                <Form.Item {...layout}
                                                    required
                                                    label="Supplier Name"
                                                >
                                                    <Input disabled value={data.supplier_name}/>
                                                </Form.Item>
                                                <Form.Item {...layout}
                                                    required
                                                    label="Part Number"
                                                >
                                                    <Input disabled value={data.part_number}/>
                                                </Form.Item>
                                            </Col>
                                            <Row justify="end">
                                                <Col>
                                                    <Space>
                                                        <Button type="primary" onClick={saveData} loading={loading} htmlType="submit" className="mr-button">
                                                        Submit
                                                        </Button>
                                                        <Button type="default" onClick={handleReset} htmlType="button" className="mr-button">
                                                        Reset
                                                        </Button>
                                                    </Space>
                                                </Col>
                                            </Row>
                                        </Row>
                                    </Form>
                                </TabPane>
                            </Tabs>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    )
}

export default Wip
