import React, { useState, useEffect, useRef, useMemo, Fragment } from 'react'
import {Card,Typography,Menu,Dropdown,Layout, Breadcrumb, DatePicker, Row,Col,Button,Modal,Input,Table, message, Space, Form, Badge, Checkbox} from 'antd';
import {Link, Redirect} from 'react-router-dom';
import Axios from 'axios';
import {url} from '../../config/global'
import { MoreOutlined, EditOutlined, DeleteOutlined, PlusOutlined, ReloadOutlined, UploadOutlined, CaretDownOutlined, CameraOutlined, RedoOutlined} from '@ant-design/icons'
import moment from 'moment';
import { render } from 'less';
import QrReader from 'react-qr-reader';
import './ChecksheetWarehouse.less'

const { Title, Text } = Typography
const { Search } = Input
const { Content } = Layout
const { RangePicker} = DatePicker

const layout = {
    labelCol: {
        lg: 10,
        md: 10
    },
    wrapperCol: {
        lg: 14,
        md: 14
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
}

const ChecksheetWarehouse = () => {
  const [formattedDate, setFormattedDate] = useState(moment().format('dddd DD MMM YYYY HH:mm:ss'));
  useEffect(() => {
    const interval = setInterval(() => {
      setFormattedDate(moment().format('dddd DD MMM YYYY HH:mm:ss'));
    }, 1000);

    return () => clearInterval(interval); // Clear the interval on component unmount
  }, []);
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState({
      sort: 'name',
      order: 'ascend'
  })

  const [checkSheets, setCheckSheet] = useState([]) // Use this for the table data

  const [keyword, setKeyword] = useState('')

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isScanModalVisible, setIsScanModalVisible] = useState(false);
  const [success, setSuccess] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [searchDisabled, setSearchDisabled] = useState(false);
  const inputRef = useRef(null);
  const [scanMaterial, setScanMaterial] = useState('');
  const [messageQr, setMessageQr] = useState('');
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [item, setItem] = useState({
      no_invoice: '',
      part_number: '',
      description: '',
      total_qty: '',
      satuan: '',
      qty_actual_receipt: '',
      tgl_receipt: '',
      unique_code: '',
  })
  const [error, setError] = useState(null)

  const handleTableChange = (pagination, filters, sorter) => {
      setPage(pagination.current)
      setSort({
          sort: sorter.field,
          order: sorter.order
      })
  }

  const pagination = {
    pageSize: 10,
    current: page,
    total: checkSheets.total,
    showSizeChanger: false,
    onChange: (page) => setPage(page),
  };

  const handleSearch = (value) => {
    setKeyword(value);
    sessionStorage.setItem("keyword_invoice", value);
    setPage(1);
    if (value !== "") {
      getData(value);
    }
  };

  const getData = (keyword) => {
    console.log("generate data");
      setLoading(true);
      setIsDisabled(true);
      setSearchDisabled(true);
      Axios.get(`${url}/warehouse-checksheets`, {
          params: {
              keyword,
              columns: [
                  'number',
              ],
              perpage: 10,
              page,
              sort: sort.sort,
              order: sort.order,
          },
          headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
      }).then(res => {
        console.log(res.data);
        setCheckSheet(res.data)
        setTimeout(() => {
          inputRef.current.focus(); // Set focus after enabling
        }, 1000); // 
      }).catch(err => {
          if (err.response) {  
              message.error(err.response.data.message)
              if (err.response.status === 401) {
                  sessionStorage.removeItem('token')
              }
          } else {
              message.error('Backend not yet Started')
          }
      }).finally(() => {
          setIsDisabled(false)
          setSearchDisabled(false)
          setLoading(false)
      })
  }

  // Fungsi untuk menangani perubahan input berdasarkan _id
  const handleInputChange = (value, id, field) => {
    const updatedSheets = checkSheets.data.map((sheet) => {
      if (sheet._id === id) {
        return { ...sheet, [field]: value };  // Update field yang sesuai
      }
      return sheet;  // Jika _id tidak sesuai, kembalikan data sheet yang sama
    });
    setCheckSheet({
      ...checkSheets,  // Spread the existing checkSheets object
      data: updatedSheets // Update only the data property
    });  // Set kembali data yang telah diperbarui
  };

  const handleCheckPart = async (value, id, field) => {
    setLoading(true);
    setIsDisabled(true);
    setSearchDisabled(true);
    await Axios.post(`${url}/warehouse-checksheets/update-invoice/${id}`,{
      field: field,
      value: value,
    },{
      headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    }).then(res => {
        message.success(res.data.message)
        setScanMaterial('');
        setMessageQr('Scan Qr Successfully');
    }).catch(err => {
        message.error(err.response.data.message)
        setMessageQr('Scan Qr Failed');
    }).finally(() => {
      setLoading(false);
      setIsDisabled(false);
      setSearchDisabled(false);
      setSuccess(false)
      setScanMaterial('');
      getData(keyword);
    })
    // const updatedSheets = checkSheets.data.map((sheet) => {
    //   if (sheet._id === id) {
    //     return { ...sheet, [field]: value };  // Update field yang sesuai
    //   }
    //   return sheet;  // Jika _id tidak sesuai, kembalikan data sheet yang sama
    // });
    // setCheckSheet({
    //   ...checkSheets,  // Spread the existing checkSheets object
    //   data: updatedSheets // Update only the data property
    // });  // Set kembali data yang telah diperbarui
  };

  const handleCheckKemasan = async (value, id, field) => {
    setLoading(true);
    setIsDisabled(true);
    setSearchDisabled(true);
    await Axios.post(`${url}/warehouse-checksheets/update-invoice/${id}`,{
      field: field,
      value: value,
    },{
      headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    }).then(res => {
        message.success(res.data.message)
        setScanMaterial('');
    }).catch(err => {
        message.error(err.response.data.message)
    }).finally(() => {
      setLoading(false);
      setIsDisabled(false);
      setSearchDisabled(false);
      setSuccess(false)
      setScanMaterial('');
      getData(keyword);
    })
    // const updatedSheets = checkSheets.data.map((sheet) => {
    //   if (sheet._id === id) {
    //     return { ...sheet, [field]: value };  // Update field yang sesuai
    //   }
    //   return sheet;  // Jika _id tidak sesuai, kembalikan data sheet yang sama
    // });
    // setCheckSheet({
    //   ...checkSheets,  // Spread the existing checkSheets object
    //   data: updatedSheets // Update only the data property
    // });  // Set kembali data yang telah diperbarui
  };
  
  const username = sessionStorage.getItem('fullname') ?? '-';

  const showModal = () => {
    setIsModalVisible(true);
    setSuccess(true);
  };

  const showScanModal = (record) => {
    setIsScanModalVisible(true);
    setItem({
      ...item,
      no_invoice: record.number,
      part_number:record.part_number,
      description:record.description,
      total_qty:record.quantity,
      satuan:record.uom,
      qty_actual_receipt:'',
      tgl_receipt:moment().format('YYYY/MM/DD'),
      unique_code:'',
    });
  };

  const handleCancelScanModal = () => {
    setIsScanModalVisible(false);
    setItem({
      ...item,
      no_invoice: '',
      part_number: '',
      description: '',
      total_qty: '',
      satuan: '',
      qty_actual_receipt: '',
      tgl_receipt: '',
      unique_code: '',
    });
  };

  const handleOkScanModal = async () => {
    if(item.qty_actual_receipt === ''){
      message.error("Qty Actual Receipt is required")
    }else {
      setIsScanModalVisible(false);
      setError(null)
      setLoading(true);
      setIsDisabled(true);
      setSearchDisabled(true);
      await Axios.post(`${url}/warehouse-checksheets/scan-manual`,{
        no_invoice: item.no_invoice,
        part_number: item.part_number,
        unique_code: item.unique_code,
        qty_actual_receipt: item.qty_actual_receipt,
      },{
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
      }).then(res => {
          message.success(res.data.message)
          setItem({
            ...item,
            no_invoice: '',
            part_number: '',
            description: '',
            total_qty: '',
            satuan: '',
            qty_actual_receipt: '',
            tgl_receipt: '',
            unique_code: '',
          });
      }).catch(err => {
        console.log(err.response)
        if (err.response) {
            message.error(err.response.data.message)
            if (err.response.status === 422) {
                setError(err.response.data.errors)
            }
            if (err.response.status === 401) {
                sessionStorage.removeItem('token')
            }
        } else {
            message.error('Backend not yet Started')
        }
      }).finally(() => {
        setLoading(false);
        setIsDisabled(false);
        setSearchDisabled(false);
        getData(keyword);
      })
    }
  };

  const handleChangeScanModal = (name, value) => {
    setError(null)
    setItem({
        ...item,
        [name]: value
    })
}

  const handleOk = () => {
    setIsModalVisible(false);
  };


  const handleCancel = () => {
    setIsModalVisible(false);
    setSuccess(false);  // You can stop QR scanning here
  };

  const handleRetry = () => {
      setSuccess(true)
  }

  const handleError = (err) => {
      console.log(err)
  }

  const handleScan = (value) => {
    if (value !== null) {
      console.log(value);
      setScanMaterial(value);
      setSuccess(false);
      setMessageQr('Scan Qr Successfully');
      setIsModalVisible(false);
      handlePostMaterial(value);
    }
  }

  const handlePressScanMaterial = (event) => {
    if (event.key === 'Enter') {
      // Action to trigger on pressing Enter
      handlePostMaterial(scanMaterial);
    }
  };

  const handleChangeScanMaterial = (e) => {
    setScanMaterial(e.target.value);
  };

  const handlePostMaterial = async (value) => {
    setLoading(true);
    setIsDisabled(true);
    setSearchDisabled(true);
    await Axios.post(`${url}/warehouse-checksheets/check-qr`,{
      qr: value,
    },{
      headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    }).then(res => {
        message.success(res.data.message)
        setScanMaterial('');
        setMessageQr('Scan Qr Successfully');
    }).catch(err => {
        message.error(err.response.data.message)
        setMessageQr('Scan Qr Failed');
    }).finally(() => {
      setLoading(false);
      setIsDisabled(false);
      setSearchDisabled(false);
      setScanMaterial('');
      getData(keyword);
    })
  }

  const handleSubmitted = async (part_number,no_invoice) => {
    setLoading(true);
    setIsDisabled(true);
    setSearchDisabled(true);
    await Axios.post(`${url}/warehouse-checksheets/submit`,{
      part_number: part_number,
      no_invoice: no_invoice,
    },{
      headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    }).then(res => {
        message.success(res.data.message)
    }).catch(err => {
        message.error(err.response.data.message)
    }).finally(() => {
      setLoading(false);
      setIsDisabled(false);
      setSearchDisabled(false);
      setScanMaterial('');
      setSelectedKeys([]);
      getData(keyword);
    })
  }

  const handleSubmittedAll = async () => {
    if(selectedKeys.length === 0){
      message.error("Please checklist an item")
    }else {
      setLoading(true);
      setIsDisabled(true);
      setSearchDisabled(true);
      await Axios.post(`${url}/warehouse-checksheets/submit-all`,{
        data: selectedKeys,
      },{
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
      }).then(res => {
          message.success(res.data.message)
      }).catch(err => {
          message.error(err.response.data.message)
      }).finally(() => {
        setLoading(false);
        setIsDisabled(false);
        setSearchDisabled(false);
        setScanMaterial('');
        setSelectedKeys([]);
        getData(keyword);
      })
    }
  }

  const formattedNumber = (value) => {
    return value.toLocaleString('id-ID')
  };

  // Menyimpan part_number dan no_invoice yang tidak di-disable
  const selectableKeys = (checkSheets.data || [])
  .filter((item) => item.is_submitted === 1)
  .map((item) => ({
    part_number: item.part_number,
    no_invoice: item.number,
  }));

  // Fungsi untuk memeriksa/uncheck semua checkbox yang tidak di-disable
  const onCheckAllChange = (e) => {
    setSelectedKeys(e.target.checked ? selectableKeys : []);
  };

  // Fungsi untuk memeriksa checkbox individu
  const onCheckChange = (part_number) => {
    setSelectedKeys((prevSelectedKeys) =>
      prevSelectedKeys.some((key) => key.part_number === part_number)
        ? prevSelectedKeys.filter((key) => key.part_number !== part_number)
        : [...prevSelectedKeys, selectableKeys.find((key) => key.part_number === part_number)]
    );
  };

  
  const baseColumns = [
    {
      title: (
        <Checkbox
          indeterminate={
            selectedKeys.length > 0 && selectedKeys.length < selectableKeys.length
          }
          onChange={onCheckAllChange}
          checked={selectedKeys.length === selectableKeys.length && selectableKeys.length > 0}
        />
      ),
      dataIndex: 'no',
      key: 'no',
      align:'center',
      width:50,
      render: (text, record) => (
        <Checkbox
          value={record.part_number}
          disabled={record.is_submitted !== 1}
          checked={selectedKeys.some((key) => key.part_number === record.part_number)}
          onChange={() => onCheckChange(record.part_number)}
        />
      ),
    },
    {
      title: 'PART NUMBER',
      dataIndex: 'part_number',
      key: 'part_number',
      align:'center',
      width:200,
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
      align:'center',
    },
    {
      title: 'SNP',
      align:'center',
      width:50,
      // dataIndex: 'snp',
      // key: 'snp',
      render: (text,record,index) => {
        return (formattedNumber(record.snp));
      }
    },
    {
      title: 'PACKING LIST',
      align:'center',
      children: [
        {
          title: 'Packages',
          align: 'center',
          children:[
            {
              dataIndex: 'packages',
              key: 'packages',
              align:'center',
              width:50,
            },
            {
              dataIndex: 'packages_uom',
              key: 'packages_uom',
              align:'center',
              width:50,
            },
          ]
          // render: (text, record, index) => {
          //   return (
          //     <table
          //       border={0}
          //       style={{
          //         padding: '0',
          //         borderCollapse: 'collapse',
          //         width: '100px',
          //         margin: '0 auto', // Center the table itself within the cell
          //       }}
          //     >
          //       <tbody>
          //         <tr>
          //           <td
          //             style={{
          //               border: 0,
          //               textAlign: 'center',
          //               paddingLeft: 20,
          //               paddingRight: 20,
          //             }}
          //           >
          //             {record.packages || 0}
          //           </td>
          //           <td
          //             style={{
          //               border: 0,
          //               textAlign: 'center',
          //               paddingLeft: 20,
          //               paddingRight: 20,
          //             }}
          //           >
          //             {record.packages_uom || 0}
          //           </td>
          //         </tr>
          //       </tbody>
          //     </table>
          //   );
          // },
        },
        {
          title:'Quantity',
          align:'center',
          width:50,
          // dataIndex:'quantity',
          // key:'quantity',
          render: (text,record,index) => {
            return (formattedNumber(record.quantity));
          }
        },
        {
          title:'Pcs',
          dataIndex:'uom',
          key:'uom',
          align:'center',
          width:50,
        },
      ],
    },
    {
      title: 'ACTUAL',
      align:'center',
      children: [
        {
          title:'Quantity',
          align:'center',
          width:50,
          // dataIndex:'qty_actual',
          // key:'qty_actual',
          render: (text,record,index) => {
            return (formattedNumber(record.qty_actual));
          }
        },
        {
          title:'Pcs',
          dataIndex:'uom',
          key:'uom',
          align:'center',
          width:50,
        },
        {
          title:'Balance',
          align:'center',
          width:50,
          // dataIndex:'balance',
          // key:'balance',
          render: (text,record,index) => {
            return (formattedNumber(record.balance));
          }
        },
      ],
    },
    {
      title : 'ITEM PENGECEKAN',
      align:'center',
      children: [
        {
          title: 'LABEL PART',
          align: 'center',
          render: (text, record, index) => {
            // Check if record.label_part exists
            if (record.label_part) {
              return <Badge count={record.label_part == '1' ? 'OK' : 'NG'} style={{
                backgroundColor: record.label_part == '1' ? '#52c41a' : 'red',
              }} />;
            }
        
            // If label_part doesn't exist, return the table
            return (
              <table
                border={0}
                style={{
                  padding: '0',
                  borderCollapse: 'collapse',
                  margin: '0 auto',
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        border: 0,
                        textAlign: 'center',
                        paddingLeft: 5,
                        paddingRight: 5,
                      }}
                    >
                      <Button
                        type="primary"
                        style={{ backgroundColor: '#52c41a', borderColor: '#52c41a' }}onClick={() => {
                          // Your click event logic here
                          handleCheckPart('1', record._id, 'label_part');
                        }}>
                        √
                      </Button>
                    </td>
                    <td
                      style={{
                        border: 0,
                        textAlign: 'center',
                        paddingLeft: 5,
                        paddingRight: 5,
                      }}
                    >
                      <Button type="primary" danger onClick={() => {
                          // Your click event logic here
                          handleCheckPart('0', record._id, 'label_part');
                        }}>
                        X
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            );
          },
        },
        {
          title: 'KEMASAN',
          align: 'center',
          render: (text,record,index) => {
            if(record.label_kemasan){
              return <Badge count={record.label_kemasan == '1' ? 'OK' : 'NG'} style={{
                backgroundColor: record.label_kemasan == '1' ? '#52c41a' : 'red',
              }} />
            }
            return (<table border={0} style={{ padding: '0',
              borderCollapse: 'collapse',
              margin: '0 auto', }}>
              <tbody>
                <tr>
                  <td style={{ border: 0,
                textAlign: 'center',
                paddingLeft: 5,
                paddingRight: 5,}}><Button type="primary" style={{ backgroundColor: '#52c41a', borderColor: '#52c41a' }} onClick={() => {
                  // Your click event logic here
                  handleCheckKemasan('1', record._id, 'label_kemasan');
                }}>√</Button></td>
                  <td style={{ border: 0,
                textAlign: 'center',
                paddingLeft: 5,
                paddingRight: 5}}><Button  type="primary" danger onClick={() => {
                  // Your click event logic here
                  handleCheckKemasan('0', record._id, 'label_kemasan');
                }}>X</Button></td>
                </tr>
              </tbody>
            </table>);
          }
        },
      ]
    },
    {
      title : 'PIC',
      align : 'center',
      children : [
        {
          title : 'PENERIMA',
          align : 'center',
          dataIndex:'created_by',
          key:'created_by',
        }
      ]
    },
    {
      title : 'Action',
      align : 'center',
      render: (text,record,index) => {
        return (<>
        <Button type="primary" onClick={() => showScanModal(record)} disabled={isDisabled}>
          Scan Manual
        </Button>
        <Modal
          title="Scan Manual"
          visible={isScanModalVisible}
          onOk={handleOkScanModal}
          onCancel={handleCancelScanModal}
          width={800}  
        >
          <Form 
            {...layout}
            name="control-hooks">
            <Row type="flex" gutter={[10, 20]} justify="space-around">
              <Col span={12}>
                <Form.Item
                    label="Part Number"
                    // validateStatus={error && error.part_number_internal ? 'error' : false }
                    // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                >
                    <Input value={item.part_number} disabled />
                </Form.Item>
                <Form.Item
                    label="Total Qty"
                    // validateStatus={error && error.part_number_internal ? 'error' : false }
                    // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                >
                    <Input value={formattedNumber(item.total_qty)} disabled />
                </Form.Item>
                <Form.Item
                    label="Qty Actual Receipt"
                    required
                    validateStatus={error && error.qty_actual_receipt ? 'error' : false }
                    help={error && error.qty_actual_receipt ? error.qty_actual_receipt[0] : false }
                >
                    <Input value={item.qty_actual_receipt} onChange={(e) => handleChangeScanModal('qty_actual_receipt', e.target.value)}/>
                </Form.Item>
                <Form.Item
                    label="Unique Code"
                    // validateStatus={error && error.part_number_internal ? 'error' : false }
                    // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                >
                    <Input value={item.unique_code} onChange={(e) => handleChangeScanModal('unique_code', e.target.value)}/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                      label="Description"
                      // validateStatus={error && error.part_number_internal ? 'error' : false }
                      // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                  >
                      <Input value={item.description} disabled />
                  </Form.Item>
                <Form.Item
                    label="Satuan"
                    // validateStatus={error && error.part_number_internal ? 'error' : false }
                    // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                >
                    <Input value={item.satuan} disabled />
                </Form.Item>
                <Form.Item
                    label="Tgl Receipt"
                    // validateStatus={error && error.part_number_internal ? 'error' : false }
                    // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                >
                    <Input value={item.tgl_receipt} disabled />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        </>);
      }
    },
    {
      title : 'LEADER',
      align : 'center',
      children : [
        {
          title : 'CHECK',
          align : 'center',
          render: (text,record,index) => {
            if(record.approved_by === username && record.is_submitted === 1){
              return (
                <Button type="primary"  onClick={() => {
                  // Your click event logic here
                  handleSubmitted(record.part_number,record.number);
                }}>Submit</Button>
              );
            }
          }
        }
      ]
    }
  ];
  
  
  if (!sessionStorage.getItem('token')) {
    return <Redirect to="/login" />
  }
  return (
    <Fragment>
        <Row>
          <Col xs={24}>
            <Card>
              <Title level={4}>Checksheet Warehouse</Title>
              <Row type="flex" justify="left">
                <Breadcrumb>
                  <Breadcrumb.Item>Checksheet Warehouse</Breadcrumb.Item>
                </Breadcrumb>
              </Row>
            </Card>
          </Col>
        </Row>
  
        <Row>
          <Col xs={24} md={24} lg={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[12, 12]} justify="space-around">
                <Col xs={12} md={12} lg={12}>
                    <Row>
                      <Col md={8} lg={4} className="align-right">
                          <Text strong={true} className="text-14">
                            Hari & Tanggal 
                          </Text>
                      </Col>
                      <Col>
                        <Text strong={true} className="text-14">
                          &nbsp; : &nbsp;
                        </Text>
                      </Col>
                      <Col>
                          <Text strong={true} className="text-14">
                            {formattedDate}
                          </Text>
                      </Col>
                    </Row>
                    <Row style={{marginTop:20}}>
                      <Col md={8} lg={4} className="align-right">
                         <Text strong={true} className="text-14">
                          Invoice
                         </Text>
                      </Col>
                      <Col>
                        <Text strong={true} className="text-14">
                          &nbsp; : &nbsp;
                         </Text>
                      </Col>
                      <Col md={12} lg={12}>
                          <Search
                            placeholder="No Invoice ..."
                            defaultValue={keyword}
                            onSearch={(value) => handleSearch(value)}
                            disabled={searchDisabled}
                          />
                      </Col>
                    </Row>
                </Col>
                <Col xs={12} md={12} lg={12}>
                  {/* First Row */}
                  <Row justify="end"> {/* Align this row to the right */}
                    <Col>
                      <Text strong={true} className="text-18">
                        Penulisan : &nbsp;( √ = OK ), ( X = NG ), &nbsp;( - = Tidak dipakai )
                      </Text>
                    </Col>
                  </Row>

                  {/* Second Row - Input and Button below the first row */}
                  <Row style={{ marginTop: 16 }} justify="end"> {/* Use margin for spacing and justify for alignment */}
                    <Col md={12} lg={12}>
                      <Input placeholder="Scan Material" value={scanMaterial}
                       disabled={isDisabled}  onChange={handleChangeScanMaterial}
                       onKeyPress={handlePressScanMaterial} ref={inputRef} />
                    </Col>
                    <Col>
                      <Button onClick={showModal} disabled={isDisabled}>
                        <CameraOutlined />
                      </Button>
                      <Modal
                        title="Scan QR"
                        visible={isModalVisible}
                        onCancel={handleCancel}
                        footer={null} // This removes the OK and Cancel buttons
                      >
                        {
                          success ? (
                              <QrReader
                                  delay={100}
                                  onError={handleError}
                                  onScan={handleScan}
                                  className="qrcode"
                                  showViewFinder={false}
                                  />
                          ) : (
                              
                              <div className="qrcode-fail">
                                  <p>{messageQr}</p>
                                  <p>Retry</p>
                                  <Button type="link" size="large" onClick={handleRetry}><RedoOutlined /></Button>
                              </div>
                              
                          )
                        }
                      </Modal>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Table
                    columns={baseColumns}
                    dataSource={checkSheets.data}
                    rowKey={(data) => data._id} // Make sure this is an array
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    bordered
                    scroll={{ x: 500 }}
                  />
                </Col>  
              </Row>
              {selectableKeys.length > 0 && (
              <Row>
                <Col xs={24} style={{ textAlign: 'right' }}>
                  <Button loading={loading} type="primary" onClick={handleSubmittedAll}>
                      Submit All
                  </Button>
                </Col>
              </Row>
              )}
            </Card>
          </Col>
        </Row>
      </Fragment>
  )
}

export default ChecksheetWarehouse