import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col, Card, Typography, Breadcrumb, Layout, message, Table, Input, DatePicker, Button, Space } from 'antd'
import {Link,Redirect} from 'react-router-dom';
import moment from 'moment'
import Axios from 'axios';
import {url} from '../../config/global'
import { FileOutlined } from '@ant-design/icons';
import fileDownload from 'js-file-download'

const { Title } = Typography
const { Search } = Input
const { Content } = Layout
const { RangePicker} = DatePicker

function ViewJournalStock(props) {

    const [data, setData] = useState({
        start_date: moment().startOf('month'),
        end_date: moment().endOf('month')
    })
    const [keyword, setKeyword] = useState('')
    const [journalStock, setJournalStock] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState({
        sort: 't_item',
        order: 'ascend'
    })

    //const dateFormat = 'YYYY-MM-DD';

    const handleSearch = (value) => {
        setKeyword(value)
        sessionStorage.setItem('keyword', value)
        setPage(1)

        if (value === '') {
            getData()
        }
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current)
        setSort({
            sort: sorter.field,
            order: sorter.order
        })
    }

    const handleChangeDate = (value) => {
        if (value) {
            setData({
                ...data,
                start_date: value[0],
                end_date: value[1]
            })
        } else {
            setData({
                ...data,
                start_date: undefined,
                end_date: undefined
            })
        }
    }

    useEffect(() => {
        getData()
    }, [keyword, page, sort, data.start_date, data.end_date])

    const getData = () => {
        setLoading(true)
        Axios.get(`${url}/journal-stock-details`, {
            params: {
                keyword: sessionStorage.getItem('keyword') ? sessionStorage.getItem('keyword') : keyword,
                columns: [
                    'part_number'
                ],
                perpage: 10,
                page,
                //sort: sort.sort,
                //order: sort.order,
                start_date: moment(data.start_date).format("YYYY-MM-DD"),
                end_date: moment(data.end_date).format("YYYY-MM-DD"),
                part_number: props.match.params.id
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
            
        }).then(res => {
            setJournalStock(res.data)
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
                if (err.response.status === 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const downloadReport = () => {
        Axios.get(`${url}/journal-stock/export`, {
            params: {
                keyword: sessionStorage.getItem('keyword') ? sessionStorage.getItem('keyword') : keyword,
                columns: [
                    'part_number',
                    'part_number_external',
                    'part_name'
                ],
                perpage: 10,
                page,
                sort: sort.sort,
                order: sort.order,
                start_date: moment(data.start_date).format("YYYY-MM-DD"),
                end_date: moment(data.end_date).format("YYYY-MM-DD"),
                part_number: props.match.params.id
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            },
            responseType: 'blob'
        }).then(res => {
            fileDownload(res.data, 'report_journal_stock.xlsx')
        })
    }

    const columns = [
        {
            title: 'Part Number',
            dataIndex: 'part_number',
            sorter: false
        },
        {
            title: 'Part Number External',
            dataIndex: 'part_number_external',
            sorter: false
        },
        {
            title: 'Part Name',
            dataIndex: 'part_name',
            sorter: false
        },
        {
            title: 'Date',
            dataIndex: 'date',
            sorter: false
        },
        {
            title: 'Incoming',
            dataIndex: 'incoming',
            sorter: false
        },
        {
            title: 'Outgoing',
            dataIndex: 'outgoing',
            //render: (outgoing, data) => data.outgoing && data.outgoing.stock_outgoing_formatted,
            sorter: false
        },
        {
            title: 'Total',
            dataIndex: 'total',
            sorter: false
        },
    ]

    if (!sessionStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    //const part_number = journalStock.data.find(x => x.part_number === part_number)
    //console.log(part_number)

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card>
                        <Title level={4}>View Journal Stock</Title>
                        <Row type="flex" justify="left">
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/journal-stock">Back</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>{props.match.params.id}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Row type="flex" gutter={[10, 20]} justify="space-between">
                                <Col md={14}>
                                    <Space>
                                        <RangePicker 
                                        onChange={handleChangeDate}
                                        value={[data.start_date, data.end_date]}
                                        format="DD MMMM YYYY"
                                        />
                                        {/* <Search
                                            placeholder="Search ..."
                                            onSearch={(value) => handleSearch(value)}
                                            defaultValue={sessionStorage.getItem('keyword')}
                                        /> */}
                                    </Space>
                                </Col>
                                <Col xs={24} md={10} lg={6} className="align-right">
                                    <Button type="primary" onClick={downloadReport}><FileOutlined />Export to Excel</Button>
                                </Col>

                                <Col xs={24}>
                                    <Table 
                                    //scroll={{x: 1000}}
                                    columns={columns}
                                    dataSource={journalStock.data}
                                    rowKey={(data, index) => index}
                                    pagination={{ pageSize: 10, current: page, total: journalStock.total, showSizeChanger: false }}
                                    loading={loading}
                                    onChange={handleTableChange}
                                    />   
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    )
}

export default ViewJournalStock