import React from 'react'
import NumberFormat from 'react-number-format'

function AntNumberFormat(props) {
    return (
        <NumberFormat disabled={props.disabled ? props.disabled : false} displayType={props.type === 'text' ? 'text' : 'input' } prefix={props.prefix ? props.prefix : false} value={props.value} decimalSeparator="," thousandSeparator="." className={`${props.type === 'text' ? 'text-right' :  'ant-input text-right'}`} onValueChange={props.onChange ? (e) => props.onChange(e.floatValue) : () => true } /> 
    )
}

export default AntNumberFormat
