import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  Typography,
  Table,
  message,
  Skeleton,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";

const { Title } = Typography;
const { Content } = Layout;

const layout = {
  labelCol: {
    lg: 8,
    md: 12,
  },
  wrapperCol: {
    lg: 14,
    md: 12,
  },
};

function EditSalesPrice(props) {
  const [data, setData] = useState([]);
  const [shipment, setShipment] = useState({
    t_shpm: undefined,
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [shipments, setShipments] = useState([]);

  useEffect(() => {
    getSalesPrice();
    getShipments();
  }, [shipment.t_shpm]);

  const getShipments = () => {
    Axios.get(`${url}/sales-price-external/list`, {
      params: {
        t_orno: props.match.params.id,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setShipments(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      });
  };

  const getSalesPrice = () => {
    setFetching(true);
    Axios.get(`${url}/sales-price-detail-external`, {
      params: {
        t_orno: props.match.params.id,
        t_shpm: shipment.t_shpm,
        t_bpid: data.length > 0 && data[0].t_bpid,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setFetching(false);
      });
  };

  const handleChangeSelect = (value) => {
    setError(null);
    setShipment({
      ...shipment,
      t_shpm: value,
    });
  };

  const onSaveData = () => {
    setLoading(true);

    Axios.post(
      `${url}/sales-price-update-external`,
      {},
      {
        params: {
          t_orno: props.match.params.id,
          t_shpm: shipment.t_shpm,
          t_bpid: data.length > 0 && data[0].t_bpid,
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        //props.history.push(`/sales-price/edit/${data.t_orno}`)
        getSalesPrice();
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const columns = [
    {
      title: `${"Item Number"}`,
      dataIndex: "t_item",
    },
    {
      title: `${"Item Number Customer"}`,
      dataIndex: "t_seab",
    },
    {
      title: `${"Item Name"}`,
      dataIndex: "t_dsca",
    },
    {
      title: `${"Qty Shipping"}`,
      align: "right",
      dataIndex: "t_qidl",
    },
    {
      title: `${"UoM"}`,
      dataIndex: "t_cups",
    },
    {
      title: `${"Currency"}`,
      dataIndex: "t_ccur",
    },
    {
      title: `${"Price"}`,
      align: "right",
      dataIndex: "t_pric",
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card>
            <Title level={4}>Edit Sales Price</Title>
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/sales-price">Sales Price</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Edit Sales Price</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24} md={12} lg={24}>
            <Card
              className="body-data"
              style={{ borderWidth: 2, borderRadius: 10 }}
            >
              <Form labelAlign="left" {...layout} name="control-hooks">
                <Row>
                  {fetching ? (
                    <Col sm={24}>
                      <Skeleton loading={fetching} />
                    </Col>
                  ) : (
                    <Fragment>
                      <Col xs={24} md={12} lg={12}>
                        <Form.Item label="SO Number">
                          <Input
                            disabled
                            value={data && data.length > 0 && data[0].t_orno}
                          />
                        </Form.Item>
                        <Form.Item label="Customer ID">
                          <Input
                            disabled
                            value={data && data.length > 0 && data[0].t_bpid}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item label="Shipment">
                          <Select
                            value={shipment.t_shpm}
                            showSearch
                            placeholder={"Shipment"}
                            allowClear={true}
                            onChange={handleChangeSelect}
                          >
                            {shipments &&
                              shipments.map((shpm) => {
                                return (
                                  <Select.Option
                                    key={shpm.t_shpm}
                                    value={shpm.t_shpm}
                                  >
                                    {shpm.t_shpm}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                        <Form.Item label="Customer Name">
                          <Input
                            disabled
                            value={
                              data && data.length > 0 && data[0].t_cdf_name
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Row justify="end">
                        <Col>
                          <Form.Item>
                            <Button
                              onClick={onSaveData}
                              loading={loading}
                              type="primary"
                              htmlType="submit"
                              className="mr-button"
                            >
                              Update
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Fragment>
                  )}
                </Row>
              </Form>
              <Table
                columns={columns}
                pagination={false}
                dataSource={data}
                rowKey={(data, index) => index}
                loading={fetching}
              ></Table>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default EditSalesPrice;
