import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col, Card, Typography, Breadcrumb, Layout, Button, message, Table, Input, Space, InputNumber, DatePicker, Select } from 'antd'
import {Redirect} from 'react-router-dom';
import moment from 'moment';
import Axios from 'axios';
import { ReloadOutlined, SendOutlined} from '@ant-design/icons'
import { url } from '../../config/global';

const { Title } = Typography
const { Search } = Input
const { Content } = Layout
const { RangePicker} = DatePicker
const { Option } = Select;

function WipImport(props) {

    const [data, setData] = useState({
        start_date: moment().startOf('month'),
        end_date: moment().endOf('month')
    })
    const [keyword, setKeyword] = useState('')
    const [wipImport, setWipImport] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState({
        sort: 'po_number',
        order: 'ascend'
    })
    const [loadingDetail, setLoadingDetail] = useState(false)
    const [rowDetails, setRowDetails] = useState([])
    const [wipImportDetails, setWipImportDetails] = useState([])
    const [selectedId, setSelectedId] = useState('')
    const [pageDetail, setPageDetail] = useState(1)
    const [perPageDetail, setPerPageDetail] = useState(10)
    const [wipImportDetailsTotal, setWipTotalImportDetails] = useState(0)
    const [choose, setChoose] = useState('2')

    const handleSearch = (value) => {

        setKeyword(value)
        sessionStorage.setItem('keyword', value)
        setPageDetail(1)

        if (value === '') {
            //getWipImportDetails()
            getData()
        }
    }

    const handleChangeSelect = (value) => {
        setChoose(value)
    }

    const handleChangeDate = (value) => {
        if (value) {
            setData({
                ...data,
                start_date: value[0],
                end_date: value[1]
            })
            
        } else {
            setData({
                ...data,
                start_date: undefined,
                end_date: undefined
            })
        }
    }

    useEffect(() => {
        getData()
    }, [keyword, page, sort, data.start_date, data.end_date, choose])

    const getData = () => {
        setLoading(true)

        //if kondisi saat pilih yang Receipt atau belum
        const urlWip = choose === '2' ? 'wip-service' : 'wip-service-receipt'
        
        Axios.get(`${url}/${urlWip}`, {
            params: {
                keyword: sessionStorage.getItem('keyword') ? sessionStorage.getItem('keyword') : keyword,
                columns: [
                    'po_number'
                ],
                //perpage: 10,
                page,
                sort: sort.sort,
                order: sort.order,
                start_date: moment(data.start_date).format("YYYY-MM-DD"),
                end_date: moment(data.end_date).format("YYYY-MM-DD")
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            setWipImport(res.data)
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleChangeDetails = (data) => {
        console.log(data)
        const index = data[data.length - 1]
        setRowDetails([index])
        if (index) {
            const data2 = index.split(';')
            setSelectedId(data2[0])
            getWipImportDetails(data2[0], data2[1])
        }
    }

    const getWipImportDetails = (po_number, rc_no) => {

        // const dataWip = wipImport.data.find(wip => wip.po_number === po_number)

        const urlDetail = choose === '2' ? `wip-service/details/${po_number}` : `wip-service-receipt/details/${po_number}/${rc_no}`
        
        setLoadingDetail(true)
        //Axios.get(`${url}/wip-service/details/${po_number}`,{
            Axios.get(`${url}/${urlDetail}`,{
            params: {
                keyword,
                columns: [
                    'po_number'
                ],
                //perpage: perPageDetail,
                //page: pageDetail
                pageDetail
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            setWipImportDetails(res.data.data)
            setWipTotalImportDetails(res.data.total)
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
            setLoadingDetail(false)
        })
    }

    const saveData = (po_number) => {
        setLoading(true)

            Axios.post(`${url}/wip-service`, {
                po_number,
                wipImportDetails
            }, 
            {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(res => {
                message.success(res.data.message)
                getData()
                getWipImportDetails()
                //setSelectedRowKeys([])
            }).catch(err => {
                if (err.response) {
                    message.error(err.response.data.message)
                } else {
                    message.error('Backend not yet Started')
                }
            }).finally(() => {
                setLoading(false)
            })

        setLoading(false)
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current)
        setSort({
            sort: sorter.field,
            order: sorter.order
        })
    }

    const handleChangeDetailsTable = (pagination,filter,sorter) => {
        setPageDetail(pagination.current)
        setPerPageDetail(pagination.pageSize)
    }

    const handleChangeQty = (value, po_number, po_line) => {
        //console.log('changed', value);

        //identify qty lama di detail
        const oldData = wipImportDetails
        const findIndex = oldData.findIndex(x => x.po_number === po_number && x.po_line === po_line)

        //identify qty lama dan mengganti ke angka yg diinput
        oldData[findIndex].qty_order = value

        setWipImportDetails(oldData)

    }

    
    const getImportDetails = () => {

        const columns = [
            { title: 'Status', dataIndex: 'receipt_number', key: 'receipt_number',
                render: (receipt_number) => {
                    return <img width="20" src={receipt_number !== null ? require('../../assets/img/checked.png') : require('../../assets/img/danger.png')} />
                }
            },
            { title: 'Receipt Number', dataIndex: 'receipt_number', align: 'left', key: 'receipt_number' },
            { title: 'Po Number', dataIndex: 'po_number', key: 'po_number' },
            { title: 'Part Number', dataIndex: 'part_number', align: 'left', key: 'part_number' },
            { title: 'Warehouse', dataIndex: 'warehouse', align: 'left', key: 'warehouse' },
            {   title: 'Quantity', 
                dataIndex: 'qty_order', 
                align: 'left', 
                key: 'po_number',
                render: (qty_order, data) => {
                    //cek ke detail
                    const cek = wipImportDetails.find(x => x.po_number === data.po_number && x.po_line === data.po_line)
                    const wipQty = cek ? cek.qty_order : qty_order
                    return <InputNumber defaultValue={wipQty} disabled={choose === '1'} onChange={(value) => handleChangeQty(value, data.po_number, data.po_line)} />
                }
            },
        ]
    
        
    return <Table
                scroll={{x: 1000}} 
                columns={columns} 
                rowKey={(data, index) => index} 
                loading={loadingDetail} 
                dataSource={wipImportDetails} 
                pagination={{
                    pageSize: perPageDetail,
                    current: pageDetail, 
                    total: wipImportDetailsTotal, 
                    showSizeChanger: true,
                    style: {
                        marginTop: '2rem',
                        marginBottom: 0
                    }
                }}
                onChange={handleChangeDetailsTable}
            />

    };
    
    const columns = [
        {
            title: 'PO Number',
            dataIndex: 'po_number',
            sorter: true,
            color: '',
            sortOrder: 'ascend'
        },
        {
            title: 'Receipt Number',
            dataIndex: 'receipt_number',
            sorter: true,
            color: '',
            sortOrder: 'ascend'
        },
        {
            title: 'Status',
            dataIndex: 'receipt_number',
            sorter: false,
            render: (receipt_number, data) => {
                return <img width="20" src={receipt_number !== null ? require('../../assets/img/checked.png') : require('../../assets/img/danger.png')} />
            }
        },
        {
          title: '',
          dataIndex: 'po_number',
          key: 'po_number',
          align: 'center',
          sorter: false,
          fixed: 'right',
          width: 150,
            render: (po_number, data) => {
                return (
                    <Button onClick={() => saveData(po_number)} disabled={data.receipt_number !== null} size="small" type="primary"><SendOutlined />Receive</Button>
                )
            }
        }
    ]
      
    if (!sessionStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card>
                        <Title level={4}>WIP Service</Title>
                            <Row type="flex" justify="left">
                                <Breadcrumb>
                                    <Breadcrumb.Item>WIP Service</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                    </Card>
                </Col>
            </Row>
            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Row type="flex" gutter={[10, 20]} justify="space-around">
                                <Col xs={24} md={12} lg={18}>
                                    <Space>
                                        <Button type="primary" onClick={getData}><ReloadOutlined />Refresh</Button>
                                        <RangePicker
                                            onChange={handleChangeDate}
                                            value={[data.start_date, data.end_date]}
                                            format="DD MMMM YYYY"
                                        />
                                        <Select
                                            value={choose}
                                            style={{ width: 120 }}
                                            placeholder="Select"
                                            onChange={(value) => handleChangeSelect(value)}
                                        >
                                            <Option value="1">Receipt</Option>
                                            <Option value="2">Not Yet</Option>
                                        </Select>
                                    </Space>
                                </Col>
                                <Col xs={24} md={12} lg={6} className="align-right">
                                    <Search
                                        placeholder="Search ..."
                                        onSearch={(value) => handleSearch(value)}
                                        defaultValue={sessionStorage.getItem('keyword')}
                                    />
                                </Col>
                                <Col xs={24}>
                                    <Table
                                    scroll={{x: 100}}
                                    columns={columns}
                                    expandable={{ 
                                        expandedRowRender: getImportDetails,
                                        onExpandedRowsChange: (data) => handleChangeDetails(data),
                                        expandedRowKeys: rowDetails
                                    }}
                                    dataSource={wipImport.data}
                                    rowKey={(data) => `${data.po_number};${data.receipt_number}`}
                                    onChange={handleTableChange}
                                    pagination={{ pageSize: 10, current: page, total: wipImport.total, showSizeChanger: false }}
                                    loading={loading}
                                    sort
                                    />   
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                {/* <Button onClick={() => console.log(wipImportDetails)}>Cek Qty</Button> */}
            </Content>
        </Fragment>
    )
}

export default WipImport