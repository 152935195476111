import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col, Card, Typography, Breadcrumb, Layout, message, Table, Input, DatePicker, Button, Space } from 'antd'
import {Link,Redirect} from 'react-router-dom';
import moment from 'moment'
import Axios from 'axios';
import {url} from '../../config/global'

const { Title } = Typography
const { Search } = Input
const { Content } = Layout
const { RangePicker} = DatePicker

function JournalStock(props) {

    const [data, setData] = useState({
        //start_date: moment().startOf('month'),
        //end_date: moment().endOf('month')
        part_number: ''
    })

    const [month, setMonth] = useState(moment().startOf('month'))
    const [keyword, setKeyword] = useState('')
    const [journalStock, setJournalStock] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState({
        sort: 't_item',
        order: 'ascend'
    })


    const handleSearch = (value) => {
        setKeyword(value)
        sessionStorage.setItem('keyword', value)
        setPage(1)

        if (value === '') {
            getData()
        }
    }

    const handleChangeDate = (value) => {
        if (value) {
            setMonth(value)
        } else {
            setMonth(undefined)
        }
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current)
        setSort({
            sort: sorter.field,
            order: sorter.order
        })
    }

    useEffect(() => {
        getData()
    }, [keyword, page, sort, month])

    const getData = () => {
        setLoading(true)
        Axios.get(`${url}/journal-stock`, {
            params: {
                keyword: sessionStorage.getItem('keyword') ? sessionStorage.getItem('keyword') : keyword,
                columns: [
                    'part_number'
                ],
                perpage: 10,
                page,
                sort: sort.sort,
                order: sort.order,
                // start_date: moment(month.startOf('month')).format("YYYY-MM-DD"),
                // end_date: moment(month.endOf('month')).format("YYYY-MM-DD"),
                part_number: props.match.params.id
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
            
        }).then(res => {
            setJournalStock(res.data)
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
                if (err.response.status === 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const columns = [
        {
            title: 'Part Number',
            dataIndex: 'part_number',
            sorter: true
        },
        {
            title: 'Part Name',
            dataIndex: 'part_name',
            sorter: true
        },
        {
            title: 'In',
            dataIndex: 'stock_incoming',
            align: 'right',
            sorter: true
        },
        {
            title: 'Out',
            dataIndex: 'stock_outgoing',
            align: 'right',
            sorter: true
        },
        {
            title: 'Actual',
            dataIndex: 'stock_current',
            align: 'right',
            sorter: true,
            render: (stock_current, data) => <Link to={`/journal-stock-details/view/${data.part_number}`}>{stock_current}</Link>
        },
    ]

    if (!sessionStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card>
                        <Title level={4}>Journal Stock</Title>
                        <Row type="flex" justify="left">
                            <Breadcrumb>
                                <Breadcrumb.Item>Journal Stock</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Row type="flex" gutter={[10, 20]} justify="space-between">
                                <Col md={24} className="align-right">
                                    <Space>
                                        {/* <DatePicker 
                                        onChange={handleChangeDate}
                                        value={month}
                                        picker="month"
                                        format="MMMM YYYY"
                                        /> */}

                                        <Search
                                        placeholder="Search"
                                        onSearch={(value) => handleSearch(value)}
                                        defaultValue={sessionStorage.getItem('keyword')}
                                        />
                                        
                                    </Space>
                                </Col>

                                <Col xs={24}>
                                    <Table 
                                    //scroll={{x: 1000}}
                                    columns={columns}
                                    dataSource={journalStock.data}
                                    rowKey={(data, index) => index}
                                    pagination={{ pageSize: 10, current: page, total: journalStock.total, showSizeChanger: false }}
                                    loading={loading}
                                    onChange={handleTableChange}
                                    />   
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    )
}

export default JournalStock