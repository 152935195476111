import React, { Fragment, useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Breadcrumb,
  Layout,
  Menu,
  Button,
  message,
  Dropdown,
  Table,
  Space,
  Input,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
import {
  MoreOutlined,
  InfoCircleOutlined,
  EditOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { queryByTestId } from "@testing-library/react";

const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;

function SalesPrice() {
  const [data, setData] = useState({
    photo: null,
  });
  const [keyword, setKeyword] = useState("");
  const [salesPrices, setSalesPrices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "t_orno",
    order: "ascend",
  });

  const handleSearch = (value) => {
    setKeyword(value);
    sessionStorage.setItem("keyword", value);
    setPage(1);

    if (value === "") {
      getData();
    }
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/sales-price-external`, {
      params: {
        keyword: sessionStorage.getItem("keyword")
          ? sessionStorage.getItem("keyword")
          : keyword,
        columns: ["t_stad", "t_orno", "t_shpm"],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setSalesPrices(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const columns = [
    {
      title: "SO Number",
      dataIndex: "t_orno",
      sorter: true,
    },
    {
      title: "Shipment Number",
      dataIndex: "t_shpm",
      sorter: true,
    },
    {
      title: "Customer ID",
      dataIndex: "t_stbp",
      //render: customer_code => customer_code && customer_code.t_stad,
      sorter: true,
    },
    {
      title: "Customer Name",
      dataIndex: "t_cdf_name",
      //render: customer_code => customer_code.customer_name && customer_code.customer_name.t_cdf_name,
      sorter: true,
    },
    {
      title: "",
      dataIndex: "t_orno",
      align: "right",
      sorter: false,
      fixed: "right",
      width: 30,
      render: (t_orno, data) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item key="0">
                    <Link to={`/sales-price/view/${t_orno}`}>
                      <InfoCircleOutlined /> View
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="1">
                    <Link to={`/sales-price/edit/${t_orno}`}>
                      <EditOutlined /> Edit
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item key="3">
                                    <span //onClick={() => this.handleDelete(id) } 
                                        to={`/delivery-note/${id}`}><DeleteOutlined />Delete</span>
                                </Menu.Item> */}
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card>
            <Title level={4}>Update Sales Price</Title>
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>Update Sales Price</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-between">
                <Col md={12}>
                  <Space>
                    <Button type="primary" onClick={getData}>
                      <ReloadOutlined />
                      Refresh
                    </Button>
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                    defaultValue={sessionStorage.getItem("keyword")}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 1000 }}
                    columns={columns}
                    dataSource={salesPrices.data}
                    rowKey={(data, index) => index}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: salesPrices.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                    onChange={handleTableChange}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default SalesPrice;
