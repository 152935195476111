import React, {Fragment, useState} from 'react'
import { Form, Input, Button, Select, Layout, Row, Col, Card, Breadcrumb, Typography, message } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import Axios from 'axios';
import { url } from '../../config/global';

const { Title } = Typography
const { Option } = Select;
const { Content } = Layout
const {TextArea} = Input

const layout = {
    labelCol: {
        lg: 8,
        md: 10
    },
    wrapperCol: {
        lg: 8,
        md: 14
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
}

function CreatePermission(props) {

    const [data, setData] = useState({
        name: '',
        description: '',
        url: '',
        icon:'',
        parent_id:'',
        parent_name:'',
        order_number: ''
    })

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleChange = (name, value) => {
        setError(null)
        setData({
            ...data,
            [name]: value
        })
    }

    const handleChangeSelect = (name, value) => {
        setError(null)
        setData({
            ...data,
            [`${name}_id`]: value.value,
            [`${name}_name`]: value.label
        })
    }

    const getParent = async () => {
        await Axios.get(`${url}/permission/list-parent`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            setData({
                ...data,
                permissions: res.data.data
            })
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status === 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        })
    }

    const onSaveData = () => {

        setLoading(true)
        
        Axios.post(`${url}/permission`,{
            name: data.name,
            description: data.description,
            url: data.url,
            icon: data.icon,
            parent_id: data.parent_id,
            parent_name: data.parent_name,
            order_number: data.order_number
        }, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            message.success(res.data.message)
            props.history.push('/permission')
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status === 422) {
                    setError(err.response.data.errors)
                }
                if (err.response.status === 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
            setLoading(false)
        })
    }

    const handleReset = () => {
        setData({
            name: '',
            description: '',
            url: '',
            icon:'',
            parent_id:'',
            parent_name:'',
            order_number:''
        })
    }

    if (!sessionStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card>
                        <Title level={4}>Add Permission</Title>
                        <Row type="flex" justify="left">
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/permission">Permission</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>Add Permission</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Form 
                            {...layout}
                            name="control-hooks"
                            onFinish={onSaveData}
                            >
                                <Form.Item
                                    required
                                    label="Name"
                                    validateStatus={error && error.name ? 'error' : false }
                                    help={error && error.name ? error.name[0] : false }
                                >
                                    <Input value={data.name} onChange={(e) => handleChange('name', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    label="Description"
                                    validateStatus={error && error.description ? 'error' : false }
                                    help={error && error.description ? error.description[0] : false }
                                >
                                    <TextArea value={data.description} onChange={(e) => handleChange('description', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="URL"
                                    validateStatus={error && error.url ? 'error' : false }
                                    help={error && error.url ? error.url[0] : false }
                                >
                                    <Input value={data.url} onChange={(e) => handleChange('url', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Order Number"
                                    validateStatus={error && error.order_number ? 'error' : false }
                                    help={error && error.order_number ? error.order_number[0] : false }
                                >
                                    <Input value={data.order_number} onChange={(e) => handleChange('order_number', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Icon"
                                    validateStatus={error && error.icon ? 'error' : false }
                                    help={error && error.icon ? error.icon[0] : false }
                                >
                                    <Input value={data.icon} onChange={(e) => handleChange('icon', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    label="Parent ID"
                                >
                                    <Select 
                                        labelInValue
                                        onChange={(value) => handleChangeSelect('parent', value)}
                                        onFocus={() => getParent()}
                                        showSearch 
                                        onSearch={(value) => getParent(value)} 
                                        filterOption={false}
                                        value={{ key: data.parent_id, label: data.parent_name }}
                                    >
                                        {
                                            data.permissions && data.permissions.map(permisssion => {
                                                return <Option value={permisssion._id} key={permisssion._id}>{permisssion.name}</Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item>
                                <Form.Item {...tailLayout}>
                                    <Button loading={loading} type="primary" htmlType="submit" className="mr-button">
                                        Submit
                                    </Button>
                                    <Button htmlType="reset" onClick={handleReset}>
                                    Reset
                                    </Button>
                                </Form.Item>
                                   
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    )
}

export default CreatePermission
