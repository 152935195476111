import React, {Fragment, useState, useEffect} from 'react'
import { Form, Input, Button, Layout, Row, Col, Card, Breadcrumb, Typography, message, Switch, Space } from 'antd';
import { Link, Redirect } from 'react-router-dom';
//import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { url } from '../../config/global';

const { Title } = Typography
const { Content } = Layout
const {TextArea} = Input

const layout = {
    labelCol: {
        lg: 8,
        md: 10
    },
    wrapperCol: {
        lg: 8,
        md: 14
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
}

function CreateRole(props) {

    const [data, setData] = useState({
        name: '',
        description: ''
    })

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [permissions, setPermissions] =  useState([])
    const [rolePermission, setRolePermission] = useState([])

    useEffect(() => {
        getPermission()
    }, [])

    const handleChange = (name, value) => {
        setError(null)
        setData({
            ...data,
            [name]: value
        })
    }

    const handleChangeSwitch = (name, value) => {
        
        setRolePermission({
            ...rolePermission,
            [name]: value
        })
    }

    const onSaveData = () => {

        setLoading(true)
        
        Axios.post(`${url}/role`,{
            name: data.name,
            description: data.description,
            permissions: rolePermission
        }, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            message.success(res.data.message)
            props.history.push('/role')
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status === 422) {
                    setError(err.response.data.errors)
                }
                if (err.response.status === 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
            setLoading(false)
        })
    }

    const handleReset = () => {
        setData({
            description: '',
            name: '',
        })
    }

    const getPermission = () => {
        setLoading(true)
        
        Axios.get(`${url}/permission`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
           setPermissions(res.data.data)
        }).catch(err => {
            console.log(err.response)
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status === 422) {
                    setError(err.response.data.errors)
                }
                if (err.response.status === 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
            setLoading(false)
        })   
    }

    if (!sessionStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card>
                        <Title level={4}>Add Role</Title>
                        <Row type="flex" justify="left">
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/role">Role</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>Add Role</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Form 
                            {...layout}
                            name="control-hooks"
                            onFinish={onSaveData}
                            >
                                <Form.Item
                                    required
                                    label="Name"
                                    validateStatus={error && error.name ? 'error' : false }
                                    help={error && error.name ? error.name[0] : false }
                                >
                                    <Input value={data.name} onChange={(e) => handleChange('name', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    label="Description"
                                    validateStatus={error && error.description ? 'error' : false }
                                    help={error && error.description ? error.description[0] : false }
                                >
                                    <TextArea value={data.description} onChange={(e) => handleChange('description', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    label="Permissions"
                                >
                                 
                                        <Row gutter={[10, 10]}>
                                            {
                                                permissions && permissions.map(permission => {
                                                    return (
                                                        <Col  key={permission._id}>
                                                            <Space>
                                                                <Switch checked={rolePermission[permission._id] ? rolePermission[permission._id] : false} value={permission._id} onChange={(value) => handleChangeSwitch(permission._id, value)} />
                                                                <span>{permission.name}</span>
                                                            </Space>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    
                                    
                                </Form.Item>


                                <Form.Item {...tailLayout}>
                                    <Button loading={loading} type="primary" htmlType="submit" className="mr-button">
                                        Submit
                                    </Button>
                                    <Button htmlType="reset" onClick={handleReset}>
                                    Reset
                                    </Button>
                                </Form.Item>
                                   
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    )
}

export default CreateRole
