import React, { Fragment, useState, useRef } from 'react'
import { Row, Col, Card, Typography, Breadcrumb, Layout, Tabs, Button, Input, Form, Space, message } from 'antd'
import { QrcodeOutlined } from '@ant-design/icons'
import Axios from 'axios'
import QrReader from 'react-qr-reader'
import './FinishGoods.less'
import {Redirect} from 'react-router-dom'
import { url } from '../../config/global'

const { Title } = Typography
const { Content } = Layout
const { TabPane } = Tabs

function FinishGoods() {
    const layout = {
        labelCol: {
            lg: 10,
            md: 16
        },
        wrapperCol: {
            lg: 10,
            md: 16
        },
    }

    const tailLayout = {
        wrapperCol: {
            span: 16,
        },
    }

    const [data, setData] = useState({
        production_order: '',
        kanban_number: '',
        qty: '',
        part_number: '',
        part_number_erp: '',
        qty_onhand:'',
        part_name: '',
        supplier_name: '',
        uom: ''
    })
    const [tab, setTab] = useState('1')
    const [success, setSuccess] = useState(true)
    const [loading, setLoading] = useState(false)
    const [dataDisabled, setDataDisabled] = useState({
        production_order: false,
        kanban_number: true,
        qty: true
    })

    const handleTab = (key) => {
        setTab(key)
    }

    const handleChange = (name, value) => {

        setData({
            ...data,
            [name]: value
        })
    }

    const saveData = () => {
        setLoading(true)

        const dataKanban = data.kanban_number.split(';')

        Axios.post(`${url}/finished-goods`, {
            production_order: data.production_order,
            lot_number: dataKanban[2],
            part_name: data.part_name,
            part_number: dataKanban[0],
            part_number_erp: data.part_number_erp.replace(" ", ''),
            warehouse: data.warehouse,
            qty: data.qty,
            qty_onhand: data.qty_onhand
        }, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            message.success(res.data.message)
            handleReset()
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const scanData = async (e, name) => {
        e.preventDefault();
        
        const dataKanban = data.kanban_number.split(';')

        const delimeterKanban = dataKanban.length

        if (delimeterKanban < 4 && name === 'kanban_number'){
            message.error('Bad QR Code for DN Number')
        } else {
                Axios.get(`${url}/finished-goods-scan`, {
                    params: {
                        production_order: data.production_order,
                        kanban_number: dataKanban[2],
                        part_number: dataKanban[0],
                        part_number_erp: data.part_number_erp
                    },
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('token')}`
                    }
                }).then(res => {
                    if (res.data.data) {
                        if (name === 'kanban_number') {

                            setDataDisabled({
                                ...dataDisabled,
                                kanban_number: true,
                                qty: false
                            })

                            setData({
                                ...data,
                                qty: dataKanban[1],
                                supplier_name: res.data.data.supplier_name,
                                part_name: res.data.data.part_name,
                                part_number: res.data.data.part_number,
                                part_number_erp: res.data.data.part_number_erp,
                                warehouse: res.data.data.warehouse,
                                qty_onhand: res.data.data.qty_onhand
                            })
                    
                            QtyRef.current.focus()
                            
                        } else {
                            setDataDisabled({
                                ...dataDisabled,
                                production_order: true,
                                kanban_number: false
                            })
                    
                            DnRef.current.focus()
                        }
                        
                    } else {
                        
                        message.error('Data not found!')
                            
                    }
                }).catch(err => {
                if (err.response) {
                    message.error(err.response.data.message)
                }
            })
        }
    }

    const handleReset = () => {
        setData({
            ...data,
            production_order: '',
            kanban_number: '',
            qty: '',
            part_number:'',
            qty_onhand:'',
            part_name: ''
        })

        setDataDisabled({
            ...setDataDisabled,
            production_order: false,
            kanban_number: true,
            qty: true
        })
    }

    const DnRef = useRef(null)
    const QtyRef = useRef(null)

    if (!sessionStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card>
                        <Title level={4}>Finish Goods</Title>
                            <Row type="flex" justify="left">
                                <Breadcrumb>
                                    <Breadcrumb.Item>Scan</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                    </Card>
                </Col>
            </Row>

            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Col span={24}>
                                <Tabs defaultActiveKey="1" onChange={handleTab}>
                                    <TabPane tab="Manual Input" key="1">
                                        <Form layout="horizontal" labelAlign="left">
                                            <Row>
                                                <Col xs={24} md={12} lg={12}>
                                                    <Form.Item {...layout}
                                                        required
                                                        label="Production Order"
                                                        //className="login-input"
                                                    >
                                                        <Input 
                                                            onPressEnter={(e) => scanData(e, 'production_order')}
                                                            onChange={(e) => handleChange('production_order', e.target.value)}
                                                            value={data.production_order}
                                                            disabled={dataDisabled.production_order}
                                                            prefix={<QrcodeOutlined />}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item {...layout}
                                                        required
                                                        label="Kanban"
                                                        prefix={<QrcodeOutlined />}
                                                    >
                                                        <Input 
                                                            onPressEnter={(e) => scanData(e, 'kanban_number')}
                                                            onChange={(e) => handleChange('kanban_number', e.target.value)}
                                                            value={data.kanban_number}
                                                            disabled={dataDisabled.kanban_number}
                                                            prefix={<QrcodeOutlined />}
                                                            ref={DnRef}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item {...layout}
                                                        required
                                                        label="Qty"
                                                    >
                                                        <Input
                                                            onChange={(e) => handleChange('qty', e.target.value)}
                                                            value={data.qty}
                                                            disabled
                                                            ref={QtyRef}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12} lg={12}>
                                                    <Form.Item {...layout}
                                                        required
                                                        label="Part Number"
                                                    >
                                                        <Input disabled value={data.part_number}/>
                                                    </Form.Item>
                                                    <Form.Item {...layout}
                                                        required
                                                        label="Stock On Hand"
                                                    >
                                                        <Input disabled value={data.qty_onhand}/>
                                                    </Form.Item>
                                                </Col>
                                                <Row justify="end">
                                                    <Col>
                                                        <Space>
                                                            <Button type="primary" onClick={saveData} loading={loading} htmlType="submit" className="mr-button">
                                                            Submit
                                                            </Button>
                                                            <Button type="default" onClick={handleReset} htmlType="button" className="mr-button">
                                                            Reset
                                                            </Button>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            </Row>
                                        </Form>
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    )
}

export default FinishGoods