import React, { Fragment, useState, useRef } from 'react'
import { Row, Col, Card, Typography, Breadcrumb, Layout, Tabs, Button, Input, Form, Space, message, DatePicker } from 'antd'
import { QrcodeOutlined } from '@ant-design/icons'
import QrReader from 'react-qr-reader'
import {Redirect} from 'react-router-dom'
import './Receiving.less'
import Axios from 'axios'
import { url } from '../../config/global'
import moment from 'moment'

const { Title } = Typography
const { Content } = Layout
const { TabPane } = Tabs

function Receiving() {

    const layout = {
        labelCol: {
            lg: 10,
            md: 16
            
        },
        wrapperCol: {
            lg: 10,
            md: 16
        },
    }

    const tailLayout = {
        wrapperCol: {
            span: 16,
        },
    }

    const DnRef = useRef(null)
    const QtyRef = useRef(null)

    const [data, setData] = useState({
        po_number: '',
        dn_number: '',
        qty: '',
        qty_plan:'',
        part_number: '',
        stock_onhand:'',
        part_name: '',
        supplier_name: '',
        uom: '',
        date: moment(new Date())
    })

    const [tab, setTab] = useState('1')
    const [success, setSuccess] = useState(true)
    const [loading, setLoading] = useState(false)
    const [dataDisabled, setDataDisabled] = useState({
        po_number: false,
        dn_number: true,
        qty: true
    })

    const handleTab = (key) => {
        setTab(key)
    }

    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        })
    }

    const handleReset = () => {
        setData({
            ...data,
            po_number: '',
            dn_number: '',
            qty: '',
            part_number:'',
            stock_onhand:'',
            part_name: '',
            supplier_name: '',
            uom: '',
            date: moment(new Date())
        })

        setDataDisabled({
            ...setDataDisabled,
            po_number: false,
            dn_number: true,
            qty: true
        })
    }

    const updateData = () => {
        setLoading(true)

        const dataDN = data.dn_number.split(';')

        if (data.qty > data.qty_plan){
            message.error('Quantity more than Quantity Plan DN')
            setLoading(false)
        } else {
            Axios.post(`${url}/warehouse-receiving-local`, {
                po_number: data.po_number,
                dn_number: dataDN[6],
                part_number: dataDN[0],
                qty: data.qty,
                date: moment(data.date).format('YYYY-MM-DD')
            }, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(res => {
                
                message.success(res.data.message)
                handleReset()

            }).catch(err => {
                if (err.response) {
                    message.error(err.response.data.message)
                } else {
                    message.error('Backend not yet Started')
                }
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    const scanData = async (e, name) => {
        e.preventDefault();
        
        const dataDN = data.dn_number.split(';')

        const DNdelimeter = dataDN.length

        if (DNdelimeter < 8 && name === 'dn_number'){
            message.error('Bad QR Code for DN Number')
        } else {
                Axios.get(`${url}/warehouse-receiving-local/scan`, {
                    params: {
                        po_number: data.po_number,
                        dn_number: dataDN[6],
                        part_number: dataDN[0],
                    },
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('token')}`
                    }
                }).then(res => {
                    if (res.data.data) {
                        if (name === 'dn_number') {

                            setDataDisabled({
                                ...dataDisabled,
                                dn_number: true,
                                qty: false
                            })

                            setData({
                                ...data,
                                qty: dataDN[1],
                                supplier_name: res.data.data.supplier_name,
                                part_name: res.data.data.part_name,
                                part_number: res.data.data.part_number,
                                stock_onhand: res.data.data.stock_onhand,
                                uom: res.data.data.uom,
                                qty_plan: res.data.data.qty_plan
                            })
                    
                            QtyRef.current.focus()
                            
                        } else {
                            setDataDisabled({
                                ...dataDisabled,
                                po_number: true,
                                dn_number: false
                            })
                    
                            DnRef.current.focus()
                        }
                        
                    } else {
                        
                        message.error('Data not found!')
                            
                    }
                }).catch(err => {
                if (err.response) {
                    message.error(err.response.data.message)
                } else {
                    message.error('Backend not yet Started')
                }
            })
        }
    }

    if (!sessionStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card>
                        <Title level={4}>Receiving Local</Title>
                            <Row type="flex" justify="left">
                                <Breadcrumb>
                                    <Breadcrumb.Item>Scan</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                    </Card>
                </Col>
            </Row>

            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Col span={24}>
                                <Tabs defaultActiveKey="1" onChange={handleTab}>
                                    <TabPane tab="Manual Input" key="1">
                                        <Form layout="horizontal" labelAlign="left">
                                            <Row>
                                                <Col xs={24} md={12} lg={12}>
                                                    {/* <Form.Item {...layout}
                                                        required
                                                        label="Invoice Notes"
                                                        //className="login-input"
                                                    >
                                                        <Input 
                                                            onChange={(e) => handleChange('invoice_number', e.target.value)}
                                                            value={data.invoice_number}
                                                        />
                                                    </Form.Item> */}
                                                    <Form.Item {...layout}
                                                        required
                                                        label="PO Number"
                                                        //className="login-input"
                                                    >
                                                        <Input 
                                                            onPressEnter={(e) => scanData(e, 'po_number')}
                                                            onChange={(e) => handleChange('po_number', e.target.value)}
                                                            value={data.po_number}
                                                            disabled={dataDisabled.po_number}
                                                            prefix={<QrcodeOutlined />}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item {...layout}
                                                        required
                                                        label="DN Number"
                                                    >
                                                        <Input 
                                                            onPressEnter={(e) => scanData(e, 'dn_number')}
                                                            onChange={(e) => handleChange('dn_number', e.target.value)}
                                                            value={data.dn_number}
                                                            disabled={dataDisabled.dn_number}
                                                            prefix={<QrcodeOutlined />}
                                                            ref={DnRef}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item {...layout}
                                                        required
                                                        label="Quantity"
                                                    >
                                                        <Input
                                                            suffix={data.uom}
                                                            onChange={(e) => handleChange('qty', e.target.value)}
                                                            value={data.qty}
                                                            disabled={dataDisabled.qty}
                                                            ref={QtyRef}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12} lg={12}>
                                                    <Form.Item {...layout}
                                                        required
                                                        label="Date"
                                                        //className="login-input"
                                                    >
                                                        <DatePicker 
                                                            onChange={(value) => handleChange('date', value)}
                                                            value={data.date}
                                                            showTime
                                                            format="DD MMMM YYYY HH:mm:ss"
                                                        />
                                                    </Form.Item>
                                                    <Form.Item {...layout}
                                                        required
                                                        label="Supplier Name"
                                                    >
                                                        <Input disabled value={data.supplier_name}/>
                                                    </Form.Item>
                                                    <Form.Item {...layout}
                                                        required
                                                        label="Part Number"
                                                    >
                                                        <Input disabled value={data.part_number} />
                                                    </Form.Item>
                                                    <Form.Item {...layout}
                                                        required
                                                        label="Stock On Hand"
                                                    >
                                                        <Input disabled value={data.stock_onhand}/>
                                                    </Form.Item>
                                                </Col>
                                                <Row justify="end">
                                                    <Col>
                                                        <Space>
                                                            <Button type="primary" loading={loading} onClick={updateData} htmlType="submit" className="mr-button">
                                                            Submit
                                                            </Button>
                                                            <Button type="default" onClick={handleReset} htmlType="button" className="mr-button">
                                                            Reset
                                                            </Button>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            </Row>
                                        </Form>
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    )
}

export default Receiving