import React, { Fragment, useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  Typography,
  Table,
  List,
  message,
  Skeleton,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";

const { Title } = Typography;
const { Content } = Layout;

function ViewSalesPrice(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  //const [sales, setSales] = useState([])

  useEffect(() => {
    getSalesPrice();
  }, []);

  const getSalesPrice = () => {
    setLoading(true);
    Axios.get(`${url}/sales-price-detail-external`, {
      params: {
        t_orno: props.match.params.id,
        t_shpm: props.match.params.t_shpm,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const dataview = [
    {
      title: "So Number",
      description: data && data.length > 0 && data[0].t_orno,
    },
    {
      title: "Customer ID",
      description: data && data.length > 0 && data[0].t_bpid,
    },
  ];

  //console.log(data.details)

  const dataview2 = [
    {
      title: "Shipment",
      description: data && data.length > 0 && data[0].t_shpm,
    },
    {
      title: "Customer Name",
      description: data && data.length > 0 && data[0].t_cdf_name,
    },
  ];

  const columns = [
    {
      title: `${"Item Number"}`,
      dataIndex: "t_item",
    },
    {
      title: `${"Item Number Customer"}`,
      dataIndex: "t_seab",
    },
    {
      title: `${"Item Name"}`,
      dataIndex: "t_dsca",
    },
    {
      title: `${"Qty Shipping"}`,
      dataIndex: "t_qidl",
    },
    {
      title: `${"UoM"}`,
      dataIndex: "t_cups",
    },
    {
      title: `${"Currency"}`,
      dataIndex: "t_ccur",
    },
    {
      title: `${"Price"}`,
      dataIndex: "t_pric",
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card>
            <Title level={4}>View Sales Price</Title>
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/sales-price">Sales Price</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>View Sales Price</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={40} className="pr-25 pl-25 mb-25">
                {loading ? (
                  <Col sm={24}>
                    <Skeleton loading={loading} />
                  </Col>
                ) : (
                  <Fragment>
                    <Col sm={24} md={12}>
                      <List
                        itemLayout="horizontal"
                        dataSource={dataview}
                        renderItem={(item) => (
                          <List.Item>
                            <List.Item.Meta
                              title={<strong>{item.title}</strong>}
                              description={
                                item.description ? item.description : "-"
                              }
                            />
                          </List.Item>
                        )}
                      />
                    </Col>
                    <Col sm={24} md={12}>
                      <List
                        itemLayout="horizontal"
                        dataSource={dataview2}
                        renderItem={(item) => (
                          <List.Item>
                            <List.Item.Meta
                              title={<strong>{item.title}</strong>}
                              description={
                                item.description ? item.description : "-"
                              }
                            />
                          </List.Item>
                        )}
                      />
                    </Col>
                  </Fragment>
                )}
              </Row>
              <Table
                //scroll={{x: 1000}}
                columns={columns}
                pagination={false}
                dataSource={data}
                rowKey={(data, index) => index}
                loading={loading}
              ></Table>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default ViewSalesPrice;
