import React, {Fragment, useState} from 'react'
import { Form, Input, Button, Layout, Row, Col, Card, Breadcrumb, Typography, message, Tag } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import Axios from 'axios';
import { url } from '../../config/global';

const { Title } = Typography
const { Content } = Layout

const layout = {
    labelCol: {
        lg: 8,
        md: 10
    },
    wrapperCol: {
        lg: 8,
        md: 14
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
}

function CreateSetting(props) {

    const [data, setData] = useState({
        name: '',
        value: '',
        values: []
    })

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleChange = (name, value) => {
        setError(null)
        setData({
            ...data,
            [name]: value
        })
    }

    const handleAddValue = (e) => {
        
        if (e.key === 'Enter') {
            e.preventDefault()
            setData({
                ...data,
                values: [
                    ...data.values,
                    {
                        id: Math.random(),
                        name: data.value
                    }
    
                ],
                value: ''
            })

        }
    }

    const handleDeleteValue = (id) => {
        const { values } = data
        const filterValues = values.filter(value => value.id !== id)

        setData({
            ...data,
            values: filterValues
        })

    }

    const onSaveData = () => {

        setLoading(true)
        
        Axios.post(`${url}/settings`,{
            variable: data.variable,
            value: data.values
        }, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            message.success(res.data.message)
            props.history.push('/setting')
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status === 422) {
                    setError(err.response.data.errors)
                }
                if (err.response.status == 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
            setLoading(false)
        })
    }

    const handleReset = () => {
        setData({
            variable: '',
            values: []
        })
    }

    if (!sessionStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card>
                        <Title level={4}>Add Configuration</Title>
                        <Row type="flex" justify="left">
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/setting">Configuration</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>Configuration</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Form 
                            {...layout}
                            name="control-hooks"
                            onFinish={onSaveData}
                            >
                                <Form.Item
                                    required
                                    label="Variable"
                                    validateStatus={error && error.variable ? 'error' : false }
                                    help={error && error.variable ? error.variable[0] : false }
                                >
                                    <Input value={data.variable} onChange={(e) => handleChange('variable', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Value"
                                    validateStatus={error && error.value ? 'error' : false }
                                    help={error && error.value ? error.value[0] : false }
                                >
                                    <Input value={data.value} onKeyPress={handleAddValue} onChange={(e) => handleChange('value', e.target.value)} placeholder="Press enter to add" />
                                </Form.Item>
                                <Form.Item {...tailLayout}>
                                    {
                                        data.values && data.values.map(value => {
                                            return <Tag key={value.id} closable onClose={() => handleDeleteValue(value.id)}>
                                                        {value.name}
                                                    </Tag>
                                        })
                                    }
                                </Form.Item>
                                <Form.Item {...tailLayout}>
                                    <Button loading={loading} type="primary" htmlType="submit" className="mr-button">
                                        Submit
                                    </Button>
                                    <Button htmlType="reset" onClick={handleReset}>
                                    Reset
                                    </Button>
                                </Form.Item>
                                   
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    )
}

export default CreateSetting
