import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col, Card, DatePicker, Typography, Breadcrumb, Layout, Menu, Button, message, TimePicker, Tag, Dropdown, Table, Input, Modal, Space } from 'antd'
import {Link, Redirect} from 'react-router-dom';
import moment from 'moment'
import Axios from 'axios';
import { MoreOutlined, PlusOutlined, EditOutlined, DeleteOutlined, PrinterOutlined, ReloadOutlined, FileOutlined} from '@ant-design/icons'
import { url } from '../../config/global';
import fileDownload from 'js-file-download'

const { Title } = Typography
const { Search } = Input
const { Content } = Layout

function DeliveryNote(props) {

    const [keyword, setKeyword] = useState('')
    const [deliveryNotes, setDeliveryNotes] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingDetail, setLoadingDetail] = useState(false)
    const [state, setState] = useState(false)
    const [sort, setSort] = useState({
        //sort: 'description',
        order: 'ascend'
    })
    const [pageSize, setPageSize] = useState(10)
    const [rowDetails, setRowDetails] = useState([])
    const [deliveryDetails, setDeliveryDetails] = useState([])
    const [printing, setPrinting] = useState(false)

    const [data, setData] = useState({
        date: moment(new Date()),
        qty: '',
        time: undefined,
        dn_number: '',
        part_no: '',
        seq_no: '',
        qty_remaining: '',
        pcs_kbn:'',
        order_line: ''
    })
    const [error, setError] = useState(null)
    const [selectedPo, setSelectedPo] = useState(null)
    const [title, setTitle] = useState('Add data');
    const [isEdit, setIsEdit] = useState(false)
    const [page, setPage] = useState(1)
    const [isDisabled, setIsDisabled] = useState(false)

    const handleSearch = (value) => {

        setKeyword(value)
        sessionStorage.setItem('keyword', value)
        setPage(1)

        if (value === '') {
            getData()
        }
    }

    const handlePrint = (po_no, dn_no) => {
        setPrinting(true)
        Axios.post(`${url}/delivery-note-print`, {
            po_no,
            dn_no
        },
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            },
            responseType: 'blob'
        }).then(res => {

            fileDownload(res.data, 'report.pdf')
            
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status === 422) {
                    setError(err.response.data.errors)
                }
                if (err.response.status == 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        })
        .finally(() => {
            setPrinting(false)
        })
    }

    const handleChange = (name, value) => {

        setError(null)

        if (name === 'qty') {

            setData({
                ...data,
                [name]: value
            })

        } else {

            setData({
                ...data,
                [name]: value ? value : undefined
            })
        }
    }

    useEffect(() => {
        getData()
    }, [keyword, page, sort])

    const getData = () => {
        setLoading(true)
        Axios.get(`${url}/delivery-note-external`, {
            params: {
                keyword: sessionStorage.getItem('keyword') ? sessionStorage.getItem('keyword') : keyword,
                columns: [
                    'name'
                ],
                perpage: pageSize,
                page,
                sort: sort.sort,
                order: sort.order
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            setDeliveryNotes(res.data)
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleTableChange = (pagination, filters, sorter, extra) => {
        
        setPage(pagination.current)
        setSort({
            sort: sorter.field,
            order: sorter.order
        })
        setPageSize(pagination.pageSize)
    }

    const handleChangeDetails = (data) => {
        const index = data[data.length - 1]
        setRowDetails([index])
        if (index) {
            const data2 = index.split(';')
            getDeliveryDetails(data2[0], data2[1])
        }

    }

    const getDeliveryDetails = (po_number, item) => {
        
        setLoadingDetail(true)
        Axios.get(`${url}/delivery-note-external/details/${po_number}/${item}`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            setDeliveryDetails(res.data.data)
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
            setLoadingDetail(false)
        })
    }


    const getDeliveryNoteDetails = () => {
        
        const columns = [
            { title: 'DN Number', dataIndex: 'dn_number', key: 'dn_number' },
            { title: 'Date', dataIndex: 'date', key: 'date' },
            { title: 'Time', dataIndex: 'time', key: 'time' },
            { title: 'Plan', dataIndex: 'qty_plan', align:'right', key: 'qty_plan' },
            { title: 'Actual', dataIndex: 'qty_actual', align:'right', key: 'qty_actual' },
            { title: 'Outstanding', dataIndex: 'qty_outstanding', align:'right', key: 'qty_outstanding' },
            {
                title: 'Action',
                dataIndex: 'operation',
                key: 'operation',
                render: (operation, data) => (
                    <Space>
                        <Button size="small" type="primary" disabled={data.qty_plan == data.qty_actual} onClick={() => showModalDeliveryNote(data.po_number, 'edit', data.part_no, data.seq_no, data.dn_number)}><EditOutlined />Edit</Button>
                        <Button size="small" type="primary" danger disabled={data.qty_plan == data.qty_actual} onClick={() => handleDelete(data.dn_number, data.part_no, data.seq_no, data.po_number)}><DeleteOutlined />Delete</Button>
                        <Button size="small" loading={printing} type="default" onClick={() => handlePrint(data.po_number, data.dn_number)}><PrinterOutlined />Print</Button>
                    </Space>
                )
            },
        ]
    
        
        return <Table columns={columns} rowKey={(data, index) => index } loading={loadingDetail} dataSource={deliveryDetails} pagination={false} />

    };

    const showModalDeliveryNote = (po_number, type, part_no, seq_no, dn_number) => {
        setState({
          visible_category: true,
        })

        setSelectedPo([po_number, part_no])

        if (type === 'add') {
            setTitle('Add Delivery Note')
            setIsDisabled(false)
            setIsEdit(false)
            setData({
                ...data,
                date: moment(new Date()),
                qty: '',
                time: undefined,
                dn_number: '',
                part_no: '',
                seq_no: '',
                qty_remaining:'',
                pcs_kbn:'',
                order_line: ''
            })
        } else {

            const find = deliveryDetails.find(item => item.po_number === po_number && item.part_no === part_no && item.seq_no === seq_no && item.dn_number === dn_number)
            console.log(find)
            setData({
                ...data,

                qty: find.qty_plan,
                date: moment(find.datetime),
                time: moment(find.datetime),
                dn_number: find.dn_number,
                part_no: find.part_no.replace(/ /g, ''),
                seq_no: find.seq_no,
                qty_remaining: find.qty_remaining,
                pcs_kbn: find.pcs_kbn,
                order_line: find.order_line
            })

            setTitle('Edit data')
            setIsDisabled(true)
            setIsEdit(true)
        }
    };

    const handleDelete = (id, part_no, seq_no, po_no) => {

        Modal.confirm({
            title: 'Are you sure?',
            content: (
              <div>
                <p>Deleted data cannot be restored</p>
              </div>
            ),
            okText: 'Delete',
            showCancel: true,
            onCancel: () => {
                return
            },
            cancelText: 'Cancel',
            confirmLoading: loading,
            onOk: async () => {
                confirmDelete(id, part_no, seq_no, po_no)
            }
        })
    }

    const confirmDelete = (dn_no, part_no, seq_no, po_no) => {

        setLoading(true)
        Axios.delete(`${url}/delivery-note-external/${dn_no}/${part_no}/${seq_no}`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            message.success(res.data.message)
            getDeliveryDetails(po_no, part_no.replace(/ /g, ''))
            getData()
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
            setLoading(false)
        })
    }
      
    const handleCancel = e => {
        setState({
            visible_category: false,
        });    
    }

    const handleSubmitDeliveryNote = () => {

        const dataPo = deliveryNotes.data.find(item => item.po_number === selectedPo[0] && item.part_no_internal == selectedPo[1])
        let urlDelivery = 'delivery-note-external'

        let qty_remaining
        
        if (isEdit){
            qty_remaining = dataPo.qty_remaining + parseInt(data.qty)
            // message.error('Qty DN more than Remaining')
        } else {
             qty_remaining = dataPo.qty_remaining === null ? dataPo.qty : dataPo.qty_remaining
        }

        console.log(parseInt(data.qty), parseInt(qty_remaining.replace(/\./g, '')))
        // console.log(data.qty, dataPo.pcs_kbn, parseInt(data.qty) % dataPo.pcs_kbn)
        if (parseInt(data.qty) > parseInt(qty_remaining.replace(/\./g, ''))) {
            message.error('Qty DN more than Remaining')
        } else if(parseInt(data.qty) % dataPo.pcs_kbn !== 0 ) {
            message.error('Qty DN is not equal as SNP')
        } else {
            if (!isEdit) {
                urlDelivery = 'delivery-note-external'
            } else {
                urlDelivery = `delivery-note-external/${data.dn_number}/${data.part_no}/${data.seq_no}`
            }
    
            setLoading(true)
    
    
            Axios.post(`${url}/${urlDelivery}`,{
                po_number: dataPo.po_number,
                supplier_id: dataPo.supplier_id,
                supplier_name: dataPo.supplier_name,
                part_no_internal: dataPo.part_no_internal,
                part_name: dataPo.part_name,
                uom: dataPo.uom,
                date: moment(data.date).format('YYYY-MM-DD'),
                time: moment(data.time).format('HH:mm:ss'),
                qty_plan: data.qty,
                qty_remaining: dataPo.qty_remaining,
                pcs_kbn: data.pcs_kbn,
                order_line: dataPo.order_line
            }, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(res => {
                message.success(res.data.message)
                getDeliveryDetails(dataPo.po_number, dataPo.part_no_internal.replace(/ /g, ''))
                getData()
            }).catch(err => {
                if (err.response) {
                    message.error(err.response.data.message)
                    if (err.response.status === 422) {
                        setError(err.response.data.errors)
                    }
                    if (err.response.status == 401) {
                        sessionStorage.removeItem('token')
                    }
                } else {
                    message.error('Backend not yet Started')
                }
            }).finally(() => {
                setState({
                    ...state,
                    visible_category: false
                })
                setLoading(false)
            })
        }  
    }
    
    const columns = [
        {
            title: 'PO Number',
            dataIndex: 'po_number',
            sorter: true,
            color: ''
        },
        {
            title: 'PO Date',
            dataIndex: 'po_date',
            sorter: true,
            color: ''
        },
        {
            title: 'Order Line',
            dataIndex: 'order_line',
            sorter: false,
            align:'center',
            width: 100
        },
        {
            title: 'Supplier',
            dataIndex: 'supplier_name',
            sorter: true
        },
        {
            title: 'Part Number',
            dataIndex: 'part_no_external',
            sorter: true,
            width: 180
        },
        {
            title: 'Part Name',
            dataIndex: 'part_name',
            sorter: true,
            width: 200
        },
        {
            title: 'PCS Kanban',
            dataIndex: 'pcs_kbn',
            align:'right',
            sorter: true
        },
        {
            title: 'Qty Order',
            dataIndex: 'qty',
            align:'right',
            sorter: true
        },
        {
            title: 'Receiving',
            dataIndex: 'qty_receiving',
            align:'right',
            sorter: true
        },
        {
            title: 'Remaining',
            dataIndex: 'qty_remaining',
            align:'right',
            sorter: true,
            render: (qty_remaining, data) => {
                const qty = qty_remaining === null ? data.qty : qty_remaining
                return <strong>{qty <= 0 ? <Tag color="#87d068">{qty}</Tag> : <Tag color="#f50">{qty}</Tag> } </strong>
            }
        },
        {
            title: 'Percent',
            dataIndex: 'percentage',
            align:'center',
            sorter: true,
            render: (percentage) => {
                return percentage ? parseFloat(percentage).toFixed(2)+" %" : '0 %'
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            sorter: true,
            render: (status) => <Tag color={status === 'Close' ? 'blue' : status === 'Outstanding' ? 'error' : 'warning'}>{status}</Tag>
        },
        {
          title: '',
          dataIndex: 'po_number',
          align: 'right',
          sorter: false,
          fixed: 'right',
          width: 50,
            render: (po_number, data) => {
                return (
                     <Fragment>
                        <Dropdown className="pointer" overlay={
                             <Menu>
                                <Menu.Item key="1">
                                    <span onClick={() => showModalDeliveryNote(po_number, 'add', data.part_no_internal)}><PlusOutlined /> Add</span>
                                </Menu.Item>
                            </Menu>
                        } trigger={['click']}>
                            <span className="pointer" onClick={e => e.preventDefault()}>
                                <MoreOutlined />
                            </span>
                        </Dropdown>
                    </Fragment>
                )
            }
        }
    ]
      
    if (!sessionStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>

        <Modal 
            title={title}
            visible={state.visible_category}
            onCancel={handleCancel}
            destroyOnClose={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    {('Cancel')}
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={handleSubmitDeliveryNote}>
                    Save
                </Button>,
            ]}
        >
        <Row>
            <Col span={8} style={{fontWeight:'bold'}}><label>Date</label></Col>
            <Col span={16}><DatePicker disabled={isDisabled} placeholder={('Date')} onChange={(value) => handleChange('date', value)} value={data.date} format="DD MMMM YYYY" /></Col>
        </Row>
        <Row style={{marginTop:20}}>
            <Col span={8} style={{fontWeight:'bold'}}><label>Time</label></Col>
            <Col span={16}><TimePicker disabled={isDisabled} onChange={value => handleChange('time', moment(value, 'HH:mm:ss'))} value={data.time} /></Col>
        </Row>
        <Row style={{marginTop:20}}>
            <Col span={8} style={{fontWeight:'bold'}}><label>Qty</label></Col>
            <Col span={16}><Input onChange={e => handleChange('qty', e.target.value)} value={data.qty} /></Col>
        </Row>
        </Modal>
            <Row>
                <Col xs={24}>
                    <Card>
                        <Title level={4}>Delivery Note</Title>
                            <Row type="flex" justify="left">
                                <Breadcrumb>
                                    <Breadcrumb.Item>Delivery Note</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                    </Card>
                </Col>
            </Row>
            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Row type="flex" gutter={[10, 20]} justify="end">
                                <Col xs={24} md={12} lg={18}>
                                    <Space>
                                        <Link to="/delivery-note/report">
                                            <Button type="primary"><FileOutlined />Report</Button>
                                        </Link>

                                        <Button type="primary" onClick={getData}><ReloadOutlined />Refresh</Button>
                                    </Space>
                                </Col>
                                <Col xs={24} md={12} lg={6} className="align-right">
                                    <Search
                                        placeholder="Search by PO / Supplier / Part Number"
                                        onSearch={(value) => handleSearch(value)}
                                        defaultValue={sessionStorage.getItem('keyword')}
                                    />
                                </Col>
                                <Col xs={24}>
                                    <Table 
                                    scroll={{x: 1500}}
                                    columns={columns}
                                    expandable={{ 
                                        expandedRowRender: getDeliveryNoteDetails,
                                        onExpandedRowsChange: (data) => handleChangeDetails(data),
                                        expandedRowKeys: rowDetails
                                    }}
                                    dataSource={deliveryNotes.data}
                                    rowKey={(data) => `${data.po_number};${data.part_no_internal.replace(/ /g, '')}`}
                                    onChange={handleTableChange}
                                    pagination={{ pageSize: pageSize, current: page, total: deliveryNotes.total, showSizeChanger: true }}
                                    loading={loading}
                                    //expandedRowKeys={expanded}
                                    />   
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    )
}

export default DeliveryNote
