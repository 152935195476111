import React, { Fragment, useState, useRef, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Breadcrumb,
  Layout,
  Tabs,
  Radio,
  Button,
  Input,
  Form,
  Table,
  Space,
  message,
  Modal,
} from "antd";
import { QrcodeOutlined } from "@ant-design/icons";
import "./Shopping.less";
import Axios from "axios";
import { url } from "../../config/global";
import { Redirect } from "react-router-dom";
import AntNumberFormat from "../../components/AntNumberFormat";
import UIfx from "uifx";

import BadQRFormat from "../../assets/audio/bad_qr_format.mp3";
import DataNotFound from "../../assets/audio/data_not_found.mp3";
import JobNumberNotMatch from "../../assets/audio/job_number_not_match.mp3";
import LotNumberCannotBeSame from "../../assets/audio/lot_number_cannot_be_same.mp3";
import PartNotFound from "../../assets/audio/part_not_found.mp3";
import PartNumberNotMatch from "../../assets/audio/part_number_not_match.mp3";
import Buzzer from "../../assets/audio/buzzer.mp3";
import fileDownload from "js-file-download";

const _ = require("underscore");

const { Title } = Typography;
const { Content } = Layout;
const { TabPane } = Tabs;

function Shopping() {
  const layout = {
    labelCol: {
      lg: 8,
      md: 10,
    },
    wrapperCol: {
      lg: 8,
      md: 10,
    },
  };

  const [value, setValue] = useState(1);
  const [tab, setTab] = useState("1");
  const [success, setSuccess] = useState(true);
  const [data, setData] = useState({
    shipment_number: "",
    delivery_note: "",
    customer_name: "",
  });
  const [scanKanban, setScanKanban] = useState("");
  const [kanbans, setKanbans] = useState([]);
  const [qty, setQty] = useState([]);
  const [dataDisabled, setDataDisabled] = useState({
    shipment_number: false,
    delivery_note: false,
  });
  const [loading, setLoading] = useState(false);
  const [buzzer, setBuzzer] = useState(false);
  const [matrixs, setMatrixs] = useState([]);
  const [optionDisabled, setOptionDisabled] = useState({
    part_number: true,
    part_number_dn: true,
    job_no: true,
  });

  const kanbanRef = useRef(null);
  const deliveryNoteRef = useRef(null);

  useEffect(() => {
    let loop;
    if (buzzer) {
      loop = setInterval(() => {
        new UIfx(Buzzer).play();
      }, 2000);
    } else {
      clearInterval(loop);
    }

    return () => {
      clearInterval(loop);
    };
  }, [buzzer]);

  const showPopup = () => {
    Modal.confirm({
      title: "Information",
      content: (
        <div>
          <p>Stop Buzzer?</p>
        </div>
      ),
      okText: "Stop",
      autoFocusButton: null,
      onOk: async () => {
        setBuzzer(false);
      },
      cancelButtonProps: {
        hidden: true,
      },
    });
  };

  const handleTab = (key) => {
    setTab(key);
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const handleChange = (name, value) => {
    if (name === "kanban_internal") {
      setScanKanban(value);
    }

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleScanDn = (e) => {
    e.preventDefault();

    // setDataDisabled({
    //     ...dataDisabled,
    //     delivery_note: true
    // })

    kanbanRef.current.focus();
  };

  const updateQty = (e) => {
    e.preventDefault();

    const scan = e.target.value;
    const datascan = scan.split(";");
    // const currentQty = qty;
    // const find = qty.find(
    //   (number) => number.item_code.replace(/ /g, "") === datascan[0]
    // );
    const check = kanbans.find(
      (kanban) => kanban.item_code.replace(/ /g, "") === datascan[0]
    );

    const itemQty = datascan[1];
    const itemLotNo = datascan[2];

    if (check) {
      let check2 = true;
      const delivery_note = data.delivery_note;
      let job_no;
      let part_no;
      let matrixPartNo;
      let matrixJobNo;

      if (matrixs.length > 0) {
        matrixPartNo = matrixs.find((matrix) => matrix.part_number);
        matrixJobNo = matrixs.find((matrix) => !matrix.part_number);
      }

      if (value === 3) {
        job_no = delivery_note.substring(
          parseInt(matrixJobNo.start),
          parseInt(matrixJobNo.length)
        );
        check2 = job_no === check.job_no.substr(0, 7);
      }

      if (value === 2) {
        part_no = delivery_note.substring(
          parseInt(matrixPartNo.start),
          parseInt(matrixPartNo.length)
        );
        check2 =
          part_no.replace(/-/g, "") === check.item_code_fix.replace(/-/g, "");
      }

      if (check2) {
        if (!isNaN(parseInt(itemQty))) {
          if (value === 3 || value === 2) {
            setDataDisabled({
              ...data,
              delivery_note: false,
            });

            setData({
              ...data,
              delivery_note: "",
            });

            deliveryNoteRef.current.focus();
          }

          const findLot = qty.find((x) => x.lot_number === itemLotNo);

          if (findLot) {
            message.error("Lot Number Cannot Same!");
            new UIfx(LotNumberCannotBeSame).play();
            showPopup();
            setBuzzer(true);
          } else {
            const currentQty = _.where(
              [
                ...qty,
                {
                  item_code: datascan[0],
                  qty: parseInt(datascan[1]),
                  lot_number: datascan[2],
                  job_no: job_no ? job_no : "",
                  part_no: part_no ? part_no : "",
                },
              ],
              {
                item_code: datascan[0],
              }
            );

            const totalCurrentQty = _.reduce(
              currentQty,
              function (total, num) {
                return total + parseInt(num.qty);
              },
              0
            );

            const qtyItem = kanbans.find(
              (x) => x.item_code.replace(/ /g, "") === datascan[0]
            );

            console.log(kanbans, qtyItem);

            if (totalCurrentQty > qtyItem.qty_item) {
              message.error("Qty is over!");
              showPopup();
              setBuzzer(true);
            } else {
              setQty([
                ...qty,
                {
                  item_code: datascan[0],
                  qty: parseInt(datascan[1]),
                  lot_number: datascan[2],
                  job_no: job_no ? job_no : "",
                  part_no: part_no ? part_no : "",
                },
              ]);
            }
          }
        } else {
          new UIfx(BadQRFormat).play();
          message.error("Bad QR Format!");
          showPopup();
          setBuzzer(true);
        }
      } else {
        if (value === 3) {
          const job_no_error = delivery_note.substring(
            parseInt(matrixJobNo.start),
            parseInt(matrixJobNo.length)
          );
          new UIfx(JobNumberNotMatch).play();
          message.error(
            `Job number not match! DN external is ${job_no_error} but internal kanban is ${check.job_no.substr(
              0,
              7
            )}`
          );
          showPopup();
          setBuzzer(true);
        } else {
          const part_no_error = delivery_note.substring(
            parseInt(matrixPartNo.start),
            parseInt(matrixPartNo.length)
          );
          new UIfx(PartNumberNotMatch).play();
          message.error(
            `Part number not match with Delivery Note! DN external is ${part_no_error.replace(
              /-/g,
              ""
            )} but internal kanban is ${check.item_code_fix.replace(/-/g, "")}`
          );
          showPopup();
          setBuzzer(true);
        }
      }
    } else {
      new UIfx(PartNotFound).play();
      message.error("Part not found");
      showPopup();
      setBuzzer(true);
    }

    setScanKanban("");
  };

  const scanData = (e) => {
    e.preventDefault();

    Axios.post(
      `${url}/shopping-scan/scan`,
      {
        shipment_number: data.shipment_number,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status) {
          setKanbans(res.data.data);
          setMatrixs(res.data.matrix);
          setData({
            ...data,
            customer_name: res.data.data[0].customer_name,
          });

          let isPartNo = false;
          let isJobNo = false;

          isPartNo = res.data.matrix.find((matrix) => matrix.part_number);
          isJobNo = res.data.matrix.find((matrix) => !matrix.part_number);

          if (isPartNo && isJobNo) {
            setValue(2);
            setOptionDisabled({
              ...dataDisabled,
              part_number: false,
              part_number_dn: false,
              job_no: false,
            });
            deliveryNoteRef.current.focus();
          }

          if (isPartNo && !isJobNo) {
            setValue(2);
            setOptionDisabled({
              ...dataDisabled,
              part_number: false,
              part_number_dn: false,
              job_no: true,
            });
            deliveryNoteRef.current.focus();
          }

          if (!isPartNo && isJobNo) {
            setValue(3);
            setOptionDisabled({
              ...dataDisabled,
              part_number: false,
              part_number_dn: true,
              job_no: false,
            });
          }

          if (!isPartNo && !isJobNo) {
            setOptionDisabled({
              ...dataDisabled,
              part_number: false,
              part_number_dn: true,
              job_no: true,
            });
            kanbanRef.current.focus();
          }

          setDataDisabled({
            ...dataDisabled,
            shipment_number: true,
          });
        } else {
          message.error("Data not found!");

          new UIfx(DataNotFound).play();

          showPopup();
          setBuzzer(true);

          setData({
            ...data,
            shipment_number: "",
            delivery_note: "",
            customer_name: "",
          });
          setDataDisabled({
            ...setDataDisabled,
            shipment_number: false,
            delivery_note: false,
          });
          setKanbans([]);
          setQty([]);
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      });
  };

  const handleReset = () => {
    setData({
      ...data,
      shipment_number: "",
      delivery_note: "",
      customer_name: "",
    });

    setDataDisabled({
      ...setDataDisabled,
      shipment_number: false,
      delivery_note: false,
    });

    setKanbans([]);

    setQty([]);
  };

  const columns = [
    {
      title: "Part Number",
      dataIndex: "item_code",
      sorter: false,
    },
    // {
    //     title: 'Part Name',
    //     dataIndex: 'part_name',
    //     sorter: false
    // },
    {
      title: "Quantity",
      dataIndex: "qty_item",
      align: "right",
      sorter: false,
      render: (
        qty_item //parseInt(qty_item)
      ) => {
        return (
          <AntNumberFormat
            thousandSeparator="."
            decimalSeparator=","
            value={parseInt(qty_item)}
            type="text"
          />
        );
      },
    },
    {
      title: "Actual",
      dataIndex: "qty_item",
      align: "right",
      sorter: false,
      render: (qty_item, data) => {
        const currentQty = _.where(qty, {
          item_code: data.item_code.replace(/ /g, ""),
        });
        const totalCurrentQty = _.reduce(
          currentQty,
          function (total, num) {
            return total + parseInt(num.qty);
          },
          0
        );

        return (
          <AntNumberFormat
            thousandSeparator="."
            decimalSeparator=","
            value={totalCurrentQty}
            type="text"
          />
        );
      },
    },
    {
      title: "Status",
      dataIndex: "qty_item",
      sorter: false,
      render: (qty_item, data) => {
        const currentQty = _.where(qty, {
          item_code: data.item_code.replace(/ /g, ""),
        });
        const totalCurrentQty = _.reduce(
          currentQty,
          function (total, num) {
            return total + parseInt(num.qty);
          },
          0
        );

        return (
          <img
            width="20"
            src={
              totalCurrentQty === parseInt(qty_item)
                ? require("../../assets/img/checked.png")
                : require("../../assets/img/danger.png")
            }
          />
        );
      },
    },
  ];

  const totalQty = qty.reduce((total, num) => {
    return total + parseInt(num.qty);
  }, 0);

  const totalQtyKanban = kanbans.reduce((total, num) => {
    return total + parseInt(num.qty_item);
  }, 0);

  // useEffect(() => {
  //   if (totalQtyKanban > totalQty) {
  //     message.error("Qty is over");
  //     showPopup();
  //     setBuzzer(true);
  //   }
  // }, [totalQty, totalQtyKanban]);

  const updateData = () => {
    setLoading(true);
    Axios.post(
      `${url}/shopping-scan/update`,
      {
        items: qty,
        shipment_number: data.shipment_number,
        delivery_note: data.delivery_note,
        customer_name: data.customer_name,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        handleDownload(res.data.id, data.shipment_number);
        handleReset();
        if (res.data.isPrint) {
          handlePrint(res.data.id);
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDownload = (id, shipment_number) => {
    Axios.post(
      `${url}/shopping-scan/download`,
      {
        id,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        responseType: "blob",
      }
    )
      .then((res) => {
        fileDownload(res.data, `${shipment_number}.xlsx`);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      });
  };

  const handlePrint = (id) => {
    Axios.get("http://10.1.10.39:4000/print-label", {
      params: {
        id,
        user: sessionStorage.getItem("fullname"),
      },
    })
      .then((res) => {
        message.success(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card>
            <Title level={4}>Shopping Scan</Title>
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>Shopping Scan</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Col span={24}>
                <Tabs defaultActiveKey="1" onChange={handleTab}>
                  <TabPane tab="Manual Input" key="1">
                    <Form layout="horizontal">
                      <Row>
                        <Col sm={24} md={12} lg={8}>
                          <Form.Item>
                            <Input
                              className="login-input"
                              prefix={<QrcodeOutlined />}
                              placeholder="Shipment"
                              autoFocus
                              onPressEnter={(e) =>
                                scanData(e, "shipment_number")
                              }
                              onChange={(e) =>
                                handleChange("shipment_number", e.target.value)
                              }
                              value={data.shipment_number}
                              disabled={dataDisabled.shipment_number}
                            />
                          </Form.Item>
                        </Col>
                        <Col sm={24} md={8} lg={16}>
                          <Form.Item {...layout} required label="Customer">
                            <Input value={data.customer_name} disabled />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={24} md={12} lg={8}>
                          {value !== 1 && (
                            <Form.Item>
                              <Input
                                className="login-input"
                                prefix={<QrcodeOutlined />}
                                placeholder="Input DN"
                                onPressEnter={handleScanDn}
                                onChange={(e) =>
                                  handleChange("delivery_note", e.target.value)
                                }
                                value={data.delivery_note}
                                ref={deliveryNoteRef}
                                disabled={dataDisabled.delivery_note}
                              />
                            </Form.Item>
                          )}
                        </Col>
                        <Col sm={24} md={8} lg={16}>
                          <Form.Item
                            {...layout}
                            required
                            label="Kanban Internal"
                            //validateStatus={error && error.name ? 'error' : false }
                            //help={error && error.name ? error.name[0] : false }
                          >
                            <Input
                              className="login-input"
                              prefix={<QrcodeOutlined />}
                              placeholder="Kanban Internal"
                              onPressEnter={(e) => updateQty(e)}
                              onChange={(e) =>
                                handleChange("kanban_internal", e.target.value)
                              }
                              value={scanKanban}
                              ref={kanbanRef}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Form.Item>
                          <Radio.Group onChange={onChange} value={value}>
                            <Radio
                              value={1}
                              checked={value === 1 ? true : false}
                              disabled={optionDisabled.part_number}
                            >
                              Part Number
                            </Radio>
                            <Radio
                              value={2}
                              checked={value === 2 ? true : false}
                              disabled={optionDisabled.part_number_dn}
                            >
                              Part Number DN
                            </Radio>
                            <Radio
                              value={3}
                              checked={value === 3 ? true : false}
                              disabled={optionDisabled.job_no}
                            >
                              Job No DN
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Row>
                      <Row gutter={[20, 20]}>
                        <Col xs={24}>
                          <Table
                            scroll={{ x: 300 }}
                            columns={columns}
                            dataSource={kanbans}
                            rowKey={(data, index) => index}
                            //onChange={handleTableChange}
                            pagination={false}
                            //loading={loading}
                            //className="table-custom"
                            showSorterTooltip={false}
                          />
                        </Col>
                        <Col xs={24}>
                          <Space>
                            <Button
                              disabled={
                                totalQty !== totalQtyKanban ||
                                totalQtyKanban === 0
                              }
                              loading={loading}
                              onClick={updateData}
                              type="primary"
                              htmlType="submit"
                              className="mr-button"
                            >
                              Submit
                            </Button>
                            <Button
                              onClick={handleReset}
                              type="defaulr"
                              htmlType="button"
                            >
                              Reset
                            </Button>
                          </Space>
                        </Col>
                      </Row>
                    </Form>
                  </TabPane>
                </Tabs>
              </Col>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default Shopping;
